
import React,{useState,useEffect} from "react";
import {bindActionCreators} from 'redux';
import { connect } from "react-redux";  
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputArea from "components/CustomInput/CustomInputArea.js";
import CustomSelect from "components/FormsControl/CustomSelect.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from '@material-ui/core';

const EditModule =(props)=> {
  let data = props.data;  
  const [values,setValues] = useState([]);
  useEffect(()=>{
    if(data && data.values){
      setValues(data.values)
    }
  },[props])
  const constructElements = (item,index)=>{     
    return(
      <>        
        <FormGroup row>     
         <FormControlLabel control={<Checkbox name="checkedC" />} label={item.label} />
         </FormGroup>
      </>
     )  
  }

  const renderFormElements =(item,index)=>{
      return(
      <>        
        <GridItem xs={12} lg={4} key={"collabel"+index} className="mt-4">
          <InputLabel>{item.label}</InputLabel>
         </GridItem>
         <GridItem xs={12} lg={4} key={"colcheckboxvisible"+index} className="mt-4">           
              <Checkbox 
                key={`${"visible"+item.name+index}`}
                name={item.name}
                checked={item.visible}
                onChange={(e)=>{
                    setValues(preValues => {
                       preValues.forEach((val,index)=>{
                         if(val.name == e.target.name){
                            preValues[index].visible = e.target.checked
                         }
                       })
                       return [...preValues];
                    });
                }}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              /> 
         </GridItem>
         <GridItem xs={12} lg={4} key={"colcheckboxrequired"+index} className="mt-4"> 
             <Checkbox
                key={`${"required"+item.name+index}`}
                name={item.name}
                checked={item.required}
                color="primary"
                onChange={(e)=>{
                    setValues(preValues => {
                       preValues.forEach((val,index)=>{
                         if(val.name == e.target.name){
                            preValues[index].required = e.target.checked
                         }
                       })
                       return [...preValues];
                    });
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              /> 
         </GridItem>
      </>

      )
  }
  
  return (
      <>   
      
        <div>   
          <GridContainer>            
              <GridItem xs={12} lg={4} className="mt-4">

              </GridItem>
              <GridItem xs={12} lg={4} className="mt-4">
                  Active
              </GridItem>
              <GridItem xs={12} lg={4} className="mt-4">
                  Required
              </GridItem>
          </GridContainer> 
          <GridContainer> 

            {
              values && values.map((item,index)=>{
                return (
                  
                     renderFormElements(item,index)
                 
                )    
              })
              
            }
          </GridContainer> 
            
        </div>
        
      </>
    );
 
} 

const mapStateToProps = state =>{ 
	return{
	}
}
const mapDispatchToProps = dispatch =>{
	return {}
}
export default connect(mapStateToProps,mapDispatchToProps)(EditModule);
