import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import Delete from '@material-ui/icons/Delete';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from "@material-ui/core/Icon";
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'

import { actions } from "actions"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Auth, API } from "aws-amplify";
const useStyles = makeStyles(styles);


const mapStatetoProps = state => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    }
  }
}
const getForms = (props, setFormsData) => {
  const myInit = {
    headers: {
      "X-COG-KEY": props.idToken,
    },
  };
  API.get("Nimo-Service", "customerform", myInit).then((response) => {
    setFormsData(response.items);
  });
}
function Dashboard(props) {
  const [formsData, setFormsData] = useState([])
  const [dispalyAlert, setDisplayAlert] = useState(false)
  const [itemToDelete, setItemToDelete] = useState({})
  const [isDeleteProgress, setDeleteProgress] = useState(false)

  const history = useHistory();
  const confirmTodelete = (item) => {
    setItemToDelete(item);
    setDisplayAlert(true);
  }
  const publish = (item, status) => {
    item.status = status;
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: { ...item },
    };
    API.put("Nimo-Service", "customerform", myInit)
      .then((response) => {
        props.showToaster(status == "Published" ? "Published successfully" : "Unpublished successfully")
        getForms(props, setFormsData)
      })
      .catch((err) => {
        props.showToaster(status == "Published" ? "Error in published" + err : "Error in Unpublished" + err)
      });
  }
  const deleteItem = () => {
    setDisplayAlert(false)
    setDeleteProgress(true)
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        SID: itemToDelete.SID,
      },
    };
    API.del("Nimo-Service", "customerform", myInit)
      .then((response) => {
        setItemToDelete({})
        props.showToaster("Form has been removed successfully", "success")
        getForms(props, setFormsData)
        setDeleteProgress(false)
      })
      .catch((err) => {
        setDeleteProgress(false)
        props.showToaster("Failed in removed", "error")
      });
  }
  const editItem = (item) => {
    history.push("/nimo/editCustomerForm/" + item.SID.split("#")[0] + "/" + item.SID.split("#")[1])
  }
  useEffect(() => {
    if (!props.idToken) return;
    getForms(props, setFormsData)
  }, [props.idToken])
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>Forms</h4>

            </CardHeader>
            <CardBody>
              <MaterialTable
                title=""
                columns={[
                  { title: 'Name', field: 'name' },
                  {
                    title: 'EndPoint URL', field: 'endPointURL', render: rowData => {
                      return (
                        <div>
                          { !!rowData.endPointURL &&
                            <div  style={{ 'display': 'flex'}}>
                              <span>
                                  <a href={rowData.endPointURL} target="_blank">{rowData.endPointURL.substring(0, 24)}...</a>
                              </span>
                              <span style={{ 'cursor': 'pointer' }}>
                                <FileCopyIcon color="primary" onClick={() => { 
                                  props.showToaster("Copied")
                                  navigator.clipboard.writeText(rowData.endPointURL) }} />
                              </span>                               
                            </div>
                          }
                        </div>
                      )
                    }
                  },
                  {
                    title: 'Status', field: 'status', render: rowData => {
                      return (
                        <div style={{ 'display': 'flex' }}>

                          {rowData.status}
                          <div>{rowData.status !== 'Published' ?
                            <PublishIcon style={{ 'cursor': 'pointer' }} onClick={() => publish(rowData, 'Published')} />
                            :
                            <GetAppIcon style={{ 'cursor': 'pointer' }} onClick={() => publish(rowData, 'Available')} />
                          }</div>
                        </div>
                      )
                    }
                  },
                  {
                    title: 'Actions', field: 'actions', 'align': 'left', render: rowData => {
                      return (
                        <div
                        >
                          <InfoIcon style={{ 'cursor': 'pointer' }} onClick={(event) => console.log('info')} />
                          <Edit style={{ 'cursor': 'pointer' }} onClick={(event) => editItem(rowData)} />
                          <Delete style={{ 'cursor': 'pointer', color: "red" }} onClick={() => confirmTodelete(rowData)} />
                          {isDeleteProgress && rowData.SID == itemToDelete.SID ? <CircularProgress color="secondary" size={24} className={classes.buttonProgress} /> : ""}
                        </div>)
                    }
                  }
                ]}
                data={formsData}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ConfirmModal open={dispalyAlert} msg="Are you sure you want to delete this form?" confirm={deleteItem} cancel={() => { setDisplayAlert(false) }} />
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard)