export const setUserForm = (userForm) =>{
	return {type:"SET_USER_FORM",...{userForm:userForm}}
} 

export const setSteps = (steps) =>{
	return {type:"SET_STEPS",...{steps:steps}}
}
export const setCurrentStep = (stepId) =>{
	return {type:"SET_CURRENT_STEP",...{stepId:stepId}}
} 
export const setCustomerForm = (customerForm) =>{
	return {type:"SET_CUSTOMER_FORM",...{customerForm:customerForm}}
} 
export const setCurrentStepIndex = (index) =>{
	return {type:"SET_STEP_INDEX",...{stepIndex:index}}
} 

