import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
 import Button from '@material-ui/core/Button';
import CardIcon from "components/Card/CardIcon.js";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import Delete from '@material-ui/icons/Delete';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from "@material-ui/core/Icon";
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import CustomInput from "components/CustomInput/CustomInput.js";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dropzone from "react-dropzone";

import { actions } from "actions"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Auth, API } from "aws-amplify"; 
import Amplify from 'aws-amplify';
import { Storage } from "aws-amplify";
import config from 'config';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dropzoneStyle:{
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
    padding:"20px"
  },
  margin0:{
    margin:0,
    fontWeight:600
  }
});  
const mapStatetoProps = state => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      group:state.commonReducers.group
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    }
  }
} 
function Dashboard(props) {
  const [fileNames, setFileNames] = useState("")
  const [dispalyAlert, setDisplayAlert] = useState(false)  
  const [filesuccess, setFileSuccess] = useState(false)  
  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const history = useHistory();
  let acceptFileType = ".fq.gz"
  const confirmTodelete = (item) => {
    //setItemToDelete(item);
    setDisplayAlert(true);
  }  
  const onFileChange = (event)=>{
      let files = []
      if(event.target && event.target.files){
        if(event.target.files.length){
            for(let i=0;i<event.target.files.length;i++){
               files.push(event.target.files[i])
            } 
        }
      }
      console.log(files)
  } 
  const classes = useStyles();
  const fileDrop = async (e, col)=> {  
        setShowProgress(true)
        var self = this
        const convertToFile = (file) => { 
            return new Promise((resolve, reject) => {
                let fileReader = new FileReader();
                fileReader.onloadend = function () {
                    resolve(fileReader.result);
                };
                fileReader.readAsDataURL(file);
            });
        }
        const files = e; // setting the event object to 
        const s3 = new AWS.S3();
        let arr  = []
        for(let i=0;i<files.length;i++){
          if(files[i].name.indexOf(acceptFileType)!=-1){               
              //if(files[i].name.substr(files[i].name.lastIndexOf(".")) == acceptFileType){
                arr.push(files[i].name) 
              //}              
          }else{   
          }          
        } 
        setFileNames(arr) 
        let bucket = config.S3.uploadLab_sequenceing
        Amplify.configure({ 
          Storage:{
            customPrefix: {  
                private: bucket
            }    
          },
          AWSS3: {
            bucket: config.S3.upload_bucket
          },
        });
       if(arr.length){
          for(let i=0;i<files.length;i++){  
            var base64 = await convertToFile(files[i]);
            var base64FileContent = base64.split(",");
            var buf = Buffer.from(base64FileContent[1], "base64"); 
            Storage.put(files[i].name, files[i], {
                level: 'private',
                contentType: files[i].type
            })
            .then (result => {
              setProgress((prevProgress) => (100/files.length-i));
               props.showToaster(files[i].name + " File has been uploaded successfully", "success")
               if(files.length==(i+1)){
                  setFileSuccess(true)
                  setShowProgress(false)
                  setFileNames("")
                  setProgress((prevProgress) => (100));
                }
            })
            .catch(err => {
              if (err) {
                    return console.log(err);
                    setFileNames("")
                }
            });
          }
       }else{
          setShowProgress(false)
          setFileNames("")
          props.showToaster("File(s) type may be not supported", "error")
        }
        
       
    }  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>Upload Files - Lab</h4>

        

            </CardHeader>
            <CardBody>
            {showProgress? <LinearProgressWithLabel value={progress} />:""}
            <div className={classes.dropzoneStyle}>
            
              <Dropzone  onDrop={e => fileDrop(e, "property_filename")}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept={acceptFileType} />
                                                {fileNames ? (<div className={classes.margin0}>{fileNames.toString()}</div>):<p className={classes.margin0}>Drag 'n' drop some files here, or click to select files</p>}
                                                 {!fileNames.length ? <em>Only supported {acceptFileType} </em>:""} 
                                            </div>
                                        </section>
                                    )}
                                </Dropzone></div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <ConfirmModal open={dispalyAlert} msg="Are you sure you want to delete this form?" cancel={() => { setDisplayAlert(false) }} />
    </div>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress  {...props} />
      </Box> 
    </Box>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard)