export const commonActions = {}

export const showLoader = (msg) =>{
	return {type:"INPROGRESS",...{isLoader:true,msg:msg?msg:"Loading Data.. Please wait..."}}
}

export const hideLoader = () =>{
	return {type:"DONE",...{isLoader:false,msg:null}}
}

export const getLoaderStatus = () =>{
	return {type:"PROGRESS_STATUS"}
}

export const showToaster = (toasterMsg,toasterType) =>{
	return {type:"SHOW_TOASTER",...{showToaster:true,toasterMsg:toasterMsg?toasterMsg:"success",toasterType:toasterType}}
}
export const hideToaster = () =>{
	return {type:"HIDE_TOASTER",...{showToaster:false,toasterMsg:null,toasterType:null}}
}
export const showProgressbar = (progressTitle,progressStatus,progressTotal) =>{
	return {type:"SHOW_PROGRESS",...{progressBar:true,progressTitle:progressTitle?progressTitle:"",progressStatus:progressStatus,progressTotal:progressTotal}}
}
export const setSession = (username,idToken,group) =>{
	return {type:"SET_SESSION",...{
		"username":username,
		"idToken":idToken,
		"group":group,
		"idToken":idToken.jwtToken
	}}
}
export const setFormID = (formID) =>{
	return {type:"setFormID",...{formID:formID}}
}
export const setUserFormID = (userFormID) =>{
	return {type:"setUserFormID",...{userFormID:userFormID}}
}
export const setCustomerID = (customerID) =>{
	return {type:"setCustomerID",...{customerID:customerID}}
}
export const setSteppers = (steppers) =>{
	return {type:"setSteppers",...{steppers:steppers}}
}
export const setWindowSize = (width,height) =>{
	return {type:"setWindowSize",...{height:height,width:width}}
}