/* eslint-disable-next-line */

import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { actions } from "actions"
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const mapDispatchToProps = dispatch => {
  return {
    setSession: (username, session) => {
      dispatch(actions.setSession(username, session))
    },
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    }
  }
}

function ForgotPassword(props) {
  const classes = useStyles();
  const [displayUsername, setdisplayUsername] = useState("")
  const [showStep, setStep] = useState(true)
  const history = useHistory();

  const nextStep = (user) => {
    setStep(false);
    setdisplayUsername(user);
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} color="primary">
          <LockOutlinedIcon />
        </Avatar>

        <Grid container>
          <Grid item xs>
            <Either value={showStep}
              step1={<CollectUserName showToaster={props.showToaster} submit={nextStep} />}
              step2={<PasswordReset showToaster={props.showToaster} username={displayUsername} />} />
            <Link onClick={() => history.push('/page/login', { replace: true })} variant="body2" >
              Back to Login.
              </Link>
          </Grid>
        </Grid>

      </div>
    </Container>
  );
}

const Either = ({ value, step1, step2 }) => {
  return value ? step1 : step2;
};

export function CollectUserName(props) {
  const classes = useStyles();
  const [username, setUserName] = useState("")
  const getPassCode = () => {
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        props.submit(username)
      })
      .catch((err) => {
        props.showToaster(err.message, "error")
      });
  }
  return (
    <div>
      <Typography component="h1" variant="h5">
        Reset Password
        </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Username"
          name="Username"
          value={username}
          autoComplete="email"
          onChange={e => setUserName(e.target.value)}
          autoFocus
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={getPassCode}
        >
          SUBMIT
          </Button>
      </form>
    </div>
  )
}
function PasswordReset(props) {
  const history = useHistory();
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const resetPassword = () => {
    Auth.forgotPasswordSubmit(props.username, code, newPassword)
      .then((data) => {
        console.log(data);
        history.push('/page/login', { replace: true });
      })
      .catch((err) => {
        props.showToaster(err.message, "error")
      });
  }
  return (
    <div>
      <Typography component="h1" variant="h5">
        Reset Password
        </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="code"
          label="Code"
          name="Code"
          value={code}
          onChange={e => setCode(e.target.value)}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={newPassword}
          name="password"
          label="New Password"
          type="password"
          onChange={e => setNewPassword(e.target.value)}
          id="password"
          autoComplete="current-password"
        />

        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={resetPassword}
        >
          SUBMIT
          </Button>
      </form>
    </div>
  )
}
export default connect(null, mapDispatchToProps)(ForgotPassword)