import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Card from "components/Card/Card.js";
import CardContent from "@material-ui/core/CardContent";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MaterialTable from 'material-table';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { connect } from "react-redux";
import { actions } from "actions";
import { Auth, API } from "aws-amplify";
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

const mapStatetoProps = (state) => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      isLoader: state.commonReducers.isLoader
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType));
    },
    showLoader: (msg) => {
      dispatch(actions.showLoader(msg));
    },
    hideLoader: () => {
      dispatch(actions.hideLoader());
    }
  };
};     
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  processBtn: {
    marginRight: theme.spacing(1),
    float:"right"
  },
  connectorStyle: {
    marginLeft: theme.spacing(-1),
  },
  containerStyle: {
    margin: theme.spacing(5),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
  
function BatchStatus(props) {
  const classes = useStyles(); 
  const [progress, setProgress] = React.useState(false);
  const [kitBatchs, setkitBatchs] = React.useState([]); 
  const [kitBatch,setKitBatch] = React.useState("")
  const [batchStatus,setBatchStatus] = React.useState(false)
 
const validateAllKitIDs = (items)=>{ 
  for(var i=0;i<items.length;i++){
    if(items[i].sequenceMD_1 && items[i].sequenceMD_2 && items[i]["16S_HPC_Availability_1"] && items[i]["16S_HPC_Availability_2"]){
       items[i].batchStatus = true
    }
    var filterItems  = items.filter((item)=>{
        return item.batchStatus
    })
    if(filterItems.length == items.length){
        setBatchStatus(true)
    }else{
      setBatchStatus(false)
    }
    setkitBatchs(items)
  }
}
const getBatchIDStatus = () => {  
    setProgress(true)
    if(!kitBatch){
      props.showToaster("Please enter Batch ID","error");
      return
    }
    const myInit = {
      headers: { 
         "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        kitBatch: kitBatch,
        kitAction: "list",
      },
    };
    API.get("Amili-Service", "/search", myInit)
      .then((response) => { 
        setProgress(false)
        if(response.items && response.items.length){           
           validateAllKitIDs(response.items)
        }else{
          setkitBatchs([]) 
          props.showToaster("No data available ","error")
        }
      })
      .catch((err) => {
        setProgress(false)
        props.showToaster("Failed in get kitBatch "+err,"error")
      });
  }; 
  const processBatch = () => {  
    setProgress(true)
    if(!kitBatch){
      props.showToaster("Please enter Batch ID","error");
      return
    }
    const myInit = {
      headers: { 
          "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        kitBatch: kitBatch,
        kitAction: "process",
      },
    };
    API.get("Amili-Service", "/search", myInit)
      .then((response) => { 
        setProgress(false)
        if(response.items && response.items.length){           
           validateAllKitIDs(response.items)
        }else{
          setkitBatchs([]) 
          props.showToaster("No data available ","error")
        }
      })
      .catch((err) => {
        setProgress(false)
        props.showToaster("Failed in process batch "+err,"error")
      });
  }; 
   
  const handleReset = () => {
    setkitBatchs([])
    setKitBatch("")
  };

  return (
    <div className={classes.root}> 
    <GridContainer spacing={3} justify="center">
        <GridItem xs={12} sm={12} md={10}>
        
       <CustomInput
                labelText="Enter Batch ID"
                id="cname"
                formControlProps={{
                  fullWidth: true
                }} 
                inputProps={{ 
                  value:kitBatch,
                  onChange: (e) => {
                    setkitBatchs([])
                    setKitBatch(e.target.value)
                  }
                }}
              />
        </GridItem>
<Button color="primary" onClick={getBatchIDStatus} className={classes.button}>Search</Button>
<Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>  
            </GridContainer> 
            {kitBatchs && kitBatchs.length>0 && 
             <Card className={classes.root} variant="outlined">
                  <CardContent>                     
                    <Typography variant="h2" component="h2" color="textSecondary">
                       {kitBatch} {batchStatus?<CheckCircleIcon style={{color:"green"}}/>:<ErrorOutlineIcon style={{color:"red"}}/>}
                        {!batchStatus && kitBatchs.length ? <Button color="primary" onClick={processBatch} variant="contained" className={classes.processBtn}>Process</Button>:""}
                    </Typography>
                    <Typography color="textSecondary">
                     Total Kit IDs  {kitBatchs.length}
                    </Typography> 


                  </CardContent> 
                </Card>
            }
            {kitBatchs && kitBatchs.length>0 && 
            <MaterialTable
                title="" 
                columns={[
                  { title: 'Kit ID',defaultSort:"asc",cellStyle: {
                      width: '10%'
                    }, field: 'kitID'},
                  { title: 'Sequence ID',cellStyle: {
                      width: '15%'
                    }, field: 'sequenceID'},                     
                  {
                    title: 'Analysis ID', field: 'analysisID'
                  }, 
                  {
                    title: 'Sequence-MD5_1', field: 'sequenceMD_1',render: rowData => {
                      return (
                        <div>  
                          {
                            rowData["sequenceMD_1"]?"Verified":"Not Verified"
                          }
                        </div>)
                    }
                  },
                  {
                    title: 'Sequence-MD5_2', field: 'sequenceMD_2',render: rowData => {
                      return (
                        <div>  
                          {
                            rowData["sequenceMD_2"]?"Verified":"Not Verified"
                          }
                        </div>)
                    }
                  },
                  {
                    title: '16S_HPC_Availability_1', field: '16S_HPC_Availability_1',render: rowData => {
                      return (
                        <div>  
                          {
                            rowData["16S_HPC_Availability_1"]?"Verified":"Not Verified"
                          }
                        </div>)
                    }
                  },
                  {
                    title: '16S_HPC_Availability_2', field: '16S_HPC_Availability_2',render: rowData => {
                      return (
                        <div>  
                          {
                            rowData["16S_HPC_Availability_2"]?"Verified":"Not Verified"
                          }
                        </div>)
                    }
                  },
                  {
                    title: 'Overall Status', field: 'batchStatus',render: rowData => {
                      return (
                        <div>  
                          {
                            rowData["batchStatus"]?"OK":"Not OK"
                          }
                        </div>)
                    }
                  }

                ]}
                data={kitBatchs} 
                options={{
                  sorting: true,  
                  pageSize:kitBatchs.length?(kitBatchs.length<50?kitBatchs.length:50):10,
                  pageSizeOptions:[5,10,20,50,100]
                }}
              />
            }

        
        <Backdrop className={classes.backdrop} open={progress}>
          <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(BatchStatus);