import Dashboard from "@material-ui/icons/Dashboard";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SettingsIcon from '@material-ui/icons/Settings';
import ScannerIcon from '@material-ui/icons/Scanner';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UploadPage_Lab from "views/UploadPage/UploadPage_Lab.js";
import UploadPage_CSV from "views/UploadPage/UploadPage_CSV.js";
import UploadPage_Qualtrics from "views/UploadPage/UploadPage_Qualtrics.js";
import BarCode from "views/BarCode/BarCode.js";
import ScanList from "views/Scan/ScanList.js";
import Reports from "views/Reports/Reports.js";
import BatchStatusTracking from "views/Status/BatchStatus.js";
import StatusTracking from "views/Status/Status.js";
import CustomerForms from "views/Forms/CustomerForms.js";
import UserForms from "views/Forms/UserForms.js";
import FormsHome from "views/Forms/FormsHome.js";
import EditCustomerForms from "views/Forms/EditCustomerForms.js";
import Modules from "views/Modules/Modules.js";
import VirtualSettings from "views/Settings/VirtualSettings.js";

//import SurveyType from "views/Survey/SurveyType.js";
import SurveyMapping from "views/Survey/SurveyMapping.js";


import Login from 'views/Auth/Login';
import ForgotPassword from 'views/Auth/ForgotPassword.js'




const routes = [

  {
    path: "/statusTracking",
    name: "Kit ID Status",
    icon: TransferWithinAStationIcon,
    component: StatusTracking,
    layout: "/amili"
  },  
  {
    path: "/forgotpassword",
    name: "Forgot Password",
    component: ForgotPassword,
    layout: "/page"
  },
  {
    path: "/login",
    name: "Login", 
    extact:true,
    component: Login,
    layout: "/page"
  }
  
];

const barRoutes = [{
  path: "/barcode",
  name: "Create Kit IDs",
  component: BarCode,
  icon: HorizontalSplitIcon,
  layout: "/amili"
},{
    path: "/scan",
    name: "Scan Kit IDs",
    component: ScanList,
    icon: ScannerIcon,
    layout: "/amili"
  }]
  const uploadLabRoutes = [{
    path: "/upload-lab",
    name: "Upload-Lab",
    icon: CloudUploadIcon,
    component: UploadPage_Lab,
    layout: "/amili"
  },{
    path: "/upload-csv",
    name: "Import-Kits",
    icon: CloudUploadIcon,
    component: UploadPage_CSV,
    layout: "/amili"
  },{
    path: "/batchStatus",
    name: "Batch Process",
    icon: FormatListBulletedIcon,
    component: BatchStatusTracking,
    layout: "/amili"
  }/*,{
    path: "/surveyType",
    name: "Survey Type",
    icon: FormatListBulletedIcon,
    component: SurveyType,
    layout: "/amili"
  }*/,{
    path: "/surveyVersion",
    name: "Survey Mapping",
    icon: FormatListBulletedIcon,
    component: SurveyMapping,
    layout: "/amili"
  }]
  const uploadQualtricsRoutes = [{
    path: "/upload-qualtrics",
    name: "Upload-Qualtrics",
    icon: CloudUploadIcon,
    component: UploadPage_Qualtrics,
    layout: "/amili"
  }]
  const qcRoutes = [{
    path: "/report",
    name: "QC",
    icon: ListAltIcon,
    component: Reports,
    layout: "/amili"
  }]
export {
  routes,
  barRoutes,
  uploadLabRoutes,
  uploadQualtricsRoutes,
  qcRoutes
}