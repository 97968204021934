import { combineReducers } from 'redux'
import customForm from './customform'
import userformReducer from './userformReducer';
import commonReducers from './commonReducers';
//import { reducer as authReducer } from 'aws-cognito-redux-saga'
export default combineReducers({
  customForm,
  userformReducer,
  commonReducers,
  //auth: authReducer.reducer
})