import React, { useState, useEffect,useRef } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialogs from "components/Dialog/Dialogs.js";
import CardMedia from "@material-ui/core/CardMedia";
import CardFooter from "components/Card/CardFooter.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SaveIcon from "@material-ui/icons/Save";
import FormBuilder from "views/Forms/FormBuilder.js";
import { useForm } from "react-hook-form";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Navbar from "components/Navbars/Navbar.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import { routes } from "routes.js";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import Snackbar from "components/Snackbar/Snackbar.js";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import { actions } from "actions"
import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

import { Box, Divider, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Financial from "./FinacialForm/App"
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 0 0",
    float: "right",
  },
  flex: {
    display: "flex",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    flex: "1 1 auto",
  },
  h4: {
    margin: theme.spacing(1)
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  header: {
    position: "relative !important",
  },
  media: {
    height: "40px",
    backgroundSize:"unset",
    backgroundPosition:"unset",
    width: "800px", // 16:9
  },
  headerTitle: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "28px",
    borderRadius: "3px",
    textTransform: "none",
    color: whiteColor,
    fontWeight: "700",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
}));

const mapStatetoProps = (state) => { 
  return {
      isToaster: state?.commonReducers?.showToaster,
      toasterMsg: state?.commonReducers?.toasterMsg,
      toasterType: state?.commonReducers?.toasterType
   } 
 /* if (state?.userformReducer?) {
    return {
      userForm: state.userformReducer.userForm,
      steps: state.userformReducer.steps,
      userForm: state.userformReducer.userForm
    }
  }*/
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    },
    setUserForm: (userform) => {
      dispatch(actions.setUserForm(userform))
    },
    setSteps: (steps) => {
      dispatch(actions.setSteps(steps))
    },
    setCurrentStep: (id) => {
      dispatch(actions.setCurrentStep(id))
    },
    setCustomerForm: (customerForms) => {
      dispatch(actions.setCustomerForm(customerForms))
    },
    setCurrentStepIndex: (id) => {
      dispatch(actions.setCurrentStepIndex(id))
    },
  }
};

function UserForms(props) {
  const classes = useStyles();
  const useStateCallback = (initialState)=> {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);

    const setStateCallback = (state, cb) => {
      cbRef.current = cb; 
      setState(state);
    };

    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);

    return [state, setStateCallback];
  }
  const [handleData,setHandleData] = useState({
    activeStep:0,
    customerForms:{},
    completed:false,
    userForm:{},
    profile:{},
    steps:{},
    muiTheme:{},
    favIcon:"",
    logo:"",
    frameColor:"",
    currentStep:"",
    isSubmitted:false,
    isUserFormView:false,
    userData:null
  });  
  const [isLoaded, setLoaded] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const externalSteppers = ["Financial Data"]
  const [error, setError] = React.useState(false);
  /*const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [customerForms, setCustomerForms] = React.useState({});
  const [userForm, setUserForm] = React.useState({});
  const [profile, setProfile] = React.useState({});
  const [steps, setSteps] = React.useState([]);
  const [activeForm, setActiveForm] = React.useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [userData, setUserData] = React.useState(null);
  const [muiTheme, setTheme] = React.useState({});
  const [favIcon, setFavIcon] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [frameColor, setFrameColor] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);*/
  const { color } = props;
  let prevStep = 0
  const history = useHistory();
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  
  useEffect(() => {
    if (!isLoaded) {
      if(isEmpty(handleData.userForm) && (!isEmpty(handleData.customerForms) && !isEmpty(handleData.profile))){
        setLoaded(true);       
      }
      if(!isEmpty(handleData.userForm) && (!isEmpty(handleData.customerForms) && !isEmpty(handleData.profile))){
        setLoaded(true)                
      } 
    }
  }, [isLoaded,handleData]);


  useEffect(() => {
    if (props.match.params.customerformId) {
      fetchUserFormData(
        props.match.params.customerformId,
        props.match.params.userformId
      );
    }
  }, [props]);


  
  const handleStateData = (state)=>{
    setHandleData(prevState => {
      return { ...prevState, ...state }
    });     
  }
  const totalSteps = () => {
    return steps.length;
  };
  const getLength = (data) => {
    if(Array.isArray(data)){
      return data.length;  
    }else if(typeof data=="object"){
      return Object.keys(data).length
    }    
  };
  const isEmpty = (obj) =>{
      return Object.keys(obj).length === 0;
  }
  const getFaviconEl = () => {
    return document.getElementById("favicon");
  };
  const getTitle = () => {
    return document.getElementById("title");
  }; 

  const identifyExternalStepperIndex = (ud)=>{
    let index = 0
    let keys = Object.keys(ud)
    for (var i =0; i<getLength(ud); i++) {
        if(keys[i] && externalSteppers.indexOf(keys[i]) !=-1){
           index = i
        }  
    } 
    return index
  }

  const getCurrentIndex = (userform,steppers,ud)=>{
    return !userform.isSubmitted? (getLength(steppers) == 1 ? getLength(ud)-1: getLength(ud)):0
  }
  const getCurrentStep = (userform,steppers,ud)=>{
    return !userform.isSubmitted ?(getLength(steppers) == 1 ? steppers[getLength(ud)-1].stepId: steppers[getLength(ud)].stepId):steppers[0].stepId
  }
  const fetchUserFormData = (customerformId, userformId) => {
    setLoaded(false)
    let query = {
      customerformId: customerformId,
    };
    if (userformId) {
      query["userformId"] = userformId;
    }
    const myInit = {
      queryStringParameters: query,
    };
    API.get("Nimo-Service", "userform", myInit)
      .then((response) => {
        if(!response.items){
            setError(true)
        }
        let items = response.items;
        if (items.profile) {
          const theme = createMuiTheme({
            palette: {
              primary: { 500: items.profile.values[0].primaryBtnColor },
              secondary: { A400: items.profile.values[0].secondaryBtnColor },
            },
          });
          handleStateData(
            {
              profile:items.profile,
              logo:items.profile.values[0].logoUrl,
              frameColor:items.profile.values[0].frameColor,
              muiTheme:theme
            }
          )  
          getFaviconEl().href = items.profile.values[0].favIconUrl
          getTitle().innerHTML = items.profile.companyName;

        }
        if (items.customerform && !items.userform) {
          props.setUserForm({})
          props.setCustomerForm(items.customerform)
          props.setSteps(items.customerform.values.steppers)
          props.setCurrentStep(items.customerform.values.steppers[0].stepId)
          props.setCurrentStepIndex(0)
          handleStateData({
            customerForms:items.customerform,
            steps:items.customerform.values.steppers,
            currentStep:items.customerform.values.steppers[handleData.activeStep].stepId
          }) 
          
        } else if (items.customerform && items.userform) {
          handleUserFormData(items.customerform, items.userform);
        } 
        
      })
      .catch((err) => { });
  }; 

  const handleUserFormData = (customerform, userform) => {
    let ud = userform.values;
    let externalIndex = 0
    if (customerform.values) {
      let steppers = customerform.values.steppers;
      steppers.forEach((step, index) => {
        step.data.forms.forEach((form, index) => {
          let step_value = ud[step.stepId];
          if (step_value) {
            form.value = step_value[form.name];
            if (form.name == "email") {
              form.emailVerified = userform.emailVerified;
            }
          }
        });
      });    
       handleStateData({
          customerForms:customerform,
          userForm:userform,
          steps:customerform.values.steppers, 
          isSubmitted:userform.isSubmitted?userform.isSubmitted:false
        }) 
      props.setUserForm(userform)
      props.setCustomerForm(customerform)
      props.setSteps(customerform.values.steppers)
      externalIndex = identifyExternalStepperIndex(ud)
      if(userform && userform.financials && userform.financials=="inprogress"){  
         handleStateData({
          activeStep:Object.keys(steppers).length==1?externalIndex:externalIndex,
          currentStep:steppers[Object.keys(steppers).length==1?externalIndex:externalIndex].stepId
        }) 
        props.setCurrentStep(steppers[Object.keys(steppers).length==1?externalIndex:externalIndex].stepId)
        props.setCurrentStepIndex(getLength(steppers)==1?externalIndex:externalIndex)
      }else{
         handleStateData({   
            activeStep:getCurrentIndex(userform,steppers,ud),
            currentStep:getCurrentStep(userform,steppers,ud)
          }) 
          props.setCurrentStep(getCurrentStep(userform,steppers,ud))
          props.setCurrentStepIndex(getCurrentIndex(userform,steppers,ud))
      }        
      

    } 
  };
 
 
  const handleFormData = (stepIndex) => {
    let data = {};
    if(getLength(handleData.userForm)){
      data = handleData.userForm?.values
    }
    let values = {};
    values = steps[stepIndex]; 
    if(values && externalSteppers.indexOf(values.stepId) ==-1){
      data[values.stepId] = {};
      if (values && values.data.forms) {
        values.data.forms.forEach((item) => {
          data[values.stepId][item.name] = item.value;
        });
      }
    }else{ 
      if(userForm.values){
        if(!data[values.stepId]){
           data[values.stepId] = {}
        }
        data[values.stepId] = {...userForm.values[values.stepId]}  
      }
    }
    return data;
  };
  /*const handleFormData = (stepIndex) => { 
    let data = {}   
    if(getLength(props.userForm)){
      data = {...props.userForm?.values}
    }    
    let values = {};
    values = steps[stepIndex]; 
    if(values && externalSteppers.indexOf(values.stepId) ==-1){
      data[values.stepId] = {};
      if (values && values.data.forms) {
        values.data.forms.forEach((item) => {
          data[values.stepId][item.name] = item.value;
        });
      }
    }else{ 
      if(props.userForm.values){
        if(!data[values.stepId]){
           data[values.stepId] = {}
        }
        data[values.stepId] = {...props.userForm.values[values.stepId]}  
      }
    }
    return data;
  };*/

  const handleBack = () => {
      handleStateData({
        activeStep:handleData.activeStep-1,
        currentStep:steps[handleData.activeStep-1].stepId
      })
      props.setCurrentStep(steps[handleData.activeStep-1].stepId)
      props.setCurrentStepIndex(handleData.activeStep-1)
  };

  const handleStep = (step) => () => { 
     handleStateData({
        activeStep:step,
        currentStep:handleData.steps[step].stepId  
      })
     props.setCurrentStep(steps[step].stepId)
     props.setCurrentStepIndex(step)
  };

  const handleExternalNext = (data)=>{  
      handleStateData({
        userForm:data
      })    
      handleData.userForm = data;
      setTimeout(()=>{
        handleNext()
      },500)
      
  }
  const handleNext = () =>  {      
    prevStep  = handleData.activeStep
    const newActiveStep = handleData.activeStep != handleData.steps.length - 1 ? handleData.activeStep + 1 : -1;
    if (newActiveStep != -1) {
     let data = handleFormData(prevStep)  
      handleStateData({
        activeStep:newActiveStep,
        userData:data 
      })
      let datas = { values: data };
      handleFormSave(datas);  
    } else { 
        let data = handleFormData(handleData.steps.length-1)  
        handleStateData({
          activeStep:newActiveStep
        })
        let datas = { values: data };
        datas.isSubmitted = true;
        handleFormSave(datas);
        handleStateData({ 
          isSubmitted:true  
        })
    }
  };

  const resend = (verifyRequired) => { 
    if (verifyRequired.name == "mailBtn") {
      API.get("Nimo-Service", "userform", {
        queryStringParameters: { resendMail: true, SID: handleData.userForm.SID },
      })
        .then((response) => {
          let items = response.items;
          props.showToaster("Mail has been resent") 
        })
        .catch((err) => { });
    }
  };

  const handleVerifyMail = (verifyRequired) => {
    if (verifyRequired.name == "mailBtn") {
      var userdatas = handleFormData(handleData.activeStep);
      let data = { values: userdatas };
      if (userdatas[handleData.currentStep] && userdatas[handleData.currentStep].email) {
        data.Email = userdatas[handleData.currentStep].email;
      }
      if (!data.Email) return;
      handleFormSave(data, true);
    } 
  };

  const handleFormSave = (data, isVerify) => {
    let method = "post";
    if (handleData.userForm && handleData.userForm.SID) {
      method = "put";
      data.SID = handleData.userForm.SID;
    } else {
      method = "post";
    }
    data.customerID = handleData.customerForms.createdByGroup;
    data.customerformId = handleData.customerForms.SID.split("#")[0]; 
    handleAPIrequest(method, data, isVerify);    
  };

  const handleAPIrequest = (method, data, isVerify) => {
    if (method == "post" && isVerify) {
      API.post("Nimo-Service", "userform", {
        body: data,
        queryStringParameters: { verifyMail: true },
      })
        .then((response) => {          
          if(!response.items && response){
            props.showToaster("Mail has been sent")
            return;
          }
          let items = response.items;
          if (items.emailVerified) {
            props.showToaster(items.message)
            history.push(items.customerformId + "/" + items.SID)
          } else {                          
            handleStateData({
                customerForms:items.customerform,
                steps:items.customerform.values.steppers,
                userForm:items.userform?items.userform:{},
                profile:items.profile?items.profile:{}
            })
          }

        })
        .catch((err) => { });
    } else if (method == "post") {
      API.post("Nimo-Service", "userform", {
        body: data
      })
        .then((response) => {
          let items = response;
          if(!isVerify){
            history.push(data.customerformId + "/" + items.SID)
          }
        })
        .catch((err) => { });
    } else if (method == "put") {
      API.put("Nimo-Service", "userform", { body: data })
        .then((response) => {
          let items = response.items;
          handleStateData({
              customerForms:items.customerform,
              steps:items.customerform.values.steppers,
              userForm:items.userform?items.userform:{},
              profile:items.profile?items.profile:{}
          })
        })
        .catch((err) => { });
    }
  };

  let {activeStep,customerForms,completed,userForm,profile,steps,muiTheme,favIcon,logo,frameColor,currentStep,isSubmitted} = handleData 
  return (
    <> 
      <MuiThemeProvider theme={muiTheme}>
        {props.isToaster == true ? <Snackbar openSnack={props.isToaster} message={props.toasterMsg} type={props.toasterType} timeout={3000} /> : ""}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.root}>
              <AppBar position="relative">
                <Toolbar> 
                  <div className={classes.flex}>
                    <CardMedia className={classes.media} image={logo} />                     
                  </div>
                </Toolbar>
              </AppBar>
            </div>
          </GridItem>
        </GridContainer>
        

        {
          !steps.length && !isLoaded && !error? 
          <GridItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{"top":"40px","position":"relative"}}
            >

            <Skeleton animation="pulse" variant="rect" height={190} width={800} /></GridItem>: !isSubmitted && !error ? (
          <>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}> 
              </GridItem>
            </GridContainer>
            <GridItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{"top":"40px","position":"relative"}}
            >
              <GridItem
                xs={12}
                sm={12}
                md={8}
              >
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((step, index) => (
                      <Step key={step.stepId} completed={completed[index]}>
                        <StepButton
                          color="inherit"
                          onClick={handleSubmit(handleStep(index))}
                        >
                          {step.title}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper> 
                  
                 {  
                  steps[activeStep] && steps[activeStep]?.title && externalSteppers.indexOf(steps[activeStep]?.title)==-1 ? <div>
                    <React.Fragment>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Card>
                            <CardBody>
                              <FormBuilder
                                register={register}
                                verifyMail={handleVerifyMail}
                                resend={resend}
                                errors={errors}
                                data={steps[activeStep]?.data}
                              />
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      <div className={classes.buttonWrapper}>
                        <Button
                          color="secondary"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                      </Button>
                        <div className={classes.spacer} />

                        <Button
                          color="primary"
                          onClick={handleSubmit(handleNext)}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    </React.Fragment> 
                  </div>:<>
                         {
                          steps[activeStep] && steps[activeStep]?.title && externalSteppers.indexOf(steps[activeStep]?.title)!=-1 ?<Financial handleAPIrequest={handleAPIrequest} handleExternalNext={handleExternalNext} parentBack={handleBack}/>:""}
                    </>
                  }
                </GridItem>{" "}
              </GridItem>
            </GridItem>
          </>
        ) : (
        <>
          {error?<GridItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{"top":"40px","position":"relative"}}
            >

            <Card theme={muiTheme}>
                <CardHeader plain color="warning">
                  <h4 className={classes.cardTitleWhite}>
                    404 - Failed to load application details.
              </h4>
                </CardHeader> 
              </Card>
               </GridItem>:
            <GridItem
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Card theme={muiTheme}>
                <CardHeader plain color="warning">
                  <h4 className={classes.cardTitleWhite}>
                    Application has been submitted
              </h4>
                </CardHeader> 
              </Card>
            </GridItem>}
            </>
          )}
      </MuiThemeProvider>
    </>

  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(UserForms);
