const commonReducers = (state = {}, action) => {
  switch (action.type) {
    case 'INPROGRESS':
      return {
          ...state,...action
        }
      break;
    case 'DONE':
      return {
          ...state,...action
        }
      break;
    case 'SHOW_TOASTER':
      return {
          ...state,...action
        }
      break;
    case 'HIDE_TOASTER':
      return {
          ...state,...action
        }
      break;
    case 'PROGRESS_STATUS':
      return {
          ...state
        }
      break;
    case 'SHOW_PROGRESS':
      return {
          ...state,...action
        }
      break;
    case 'SET_SESSION':
      return {
          ...state,...action
        }
      break;
    case 'setFormID':
      return {
          ...state,...action
        }
      break;
    case 'setCustomerID':
      return {
          ...state,...action
        }
      break;
    case 'setUserFormID':
      return {
          ...state,...action
        }
      break;
    case 'setSteppers':
      return {
          ...state,...action
        }
      break; 
    case 'setWindowSize':
      return {
          ...state,...action
        }
      break;
    default:
      return state
  }
}

export default commonReducers