import React, { Component, useState, Fragment } from 'react';
import Dropzone from "react-dropzone";
import './custom.css';
import AWS from "aws-sdk";
/*AWS.config.update({
    accessKeyId: "67ikbdk3clprac8v20u6e4ndt5",
    secretAccessKey: "18moejtvs9hi30801cuqr8bdjntkcq32q62c7p74ghpeka4pvth1",
});
*/


class Step3 extends Component {

    constructor() {
        super();
        this.state = {
            count: 1,
            id:"liability",
            form_property: [{
                liability: "",
                liability_error: "",
                liability_description: "",
                liability_description_error: '',
                amount_owed_on_liability: "",
                amount_owed_on_liability_error: '',
                credit_limit_on_liability: "",
                credit_limit_on_liability_error: '',
                monthly_replayment_liability: "",
                monthly_replayment_liability_error: '',
                atteched_files: {},
                atteched_files_errors: "",
                count: 1
            }]
        }
    }
    componentWillMount(){
        if(this.props.data){
            this.setState({...this.props.data})
        }
    }
    handleTextChange(event, index, col) {
        var form_property = this.state.form_property
        form_property[index]["" + col + ""] = event;
        this.setState({ form_property: form_property });
        console.log(this.state.form_property)
    }

    async fileDrop(e, index, col) {
        console.log({ e, index, col });
        var self = this
        const convertPhoto = (img) => {
            return new Promise((resolve, reject) => {
                let fileReader = new FileReader();
                fileReader.onloadend = function () {
                    resolve(fileReader.result);
                };
                fileReader.readAsDataURL(img);
            });
        }
        const files = e; // setting the event object to 
        const s3 = new AWS.S3();
        let base64 = await convertPhoto(files[0]);
        const base64Data = new Buffer(
            base64.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
        );
        const imgtype = base64.split(";")[0].split("/")[1];
        const params = {
            Bucket: `nimo-merchant/userForm`,
            Key: `${files[0].name}`,
            Body: base64Data,
            ACL: "public-read-write",
            ContentEncoding: "base64", // required
            ContentType: `image/${imgtype}`,
        };

        function callback(err, data) {
            if (err) {
                return console.log(err);
            }
            var form_property = self.state.form_property            
            form_property[index][col] = data.Location
            self.setState(form_property)
            console.log(data.Location);
            //TO DO SET lOCATION OF THE

        }
        s3.upload(params, callback);
    }

    addAnother(e, index) {
        const Findindex = this.state.form_property.findIndex(item => item.count == e.count + 1)
        if (Findindex > -1) {
            const items = this.state.form_property.filter(item => item.count !== e.count + 1);
            const list = items.filter(item => item.count <= Findindex);
            this.setState({
                count: list.length,
                form_property: list
            });
        } else {
            this.state.form_property.push({
                liability: "",
                liability_error: "",
                liability_description: "",
                liability_description_error: '',
                amount_owed_on_liability: "",
                amount_owed_on_liability_error: '',
                credit_limit_on_liability: "",
                credit_limit_on_liability_error: '',
                monthly_replayment_liability: "",
                monthly_replayment_liability_error: '',
                atteched_files: {},
                atteched_files: "",
                count: this.state.count + 1,
            });
            this.setState({
                count: this.state.count + 1,
                form_property: this.state.form_property,
            });
        }
    }
    isNumber(charCode){
        return /\d/.test(String.fromCharCode(charCode));
    }
    addComma(value) {
        value = value.replace(',', ''); value = value.replace(',', ''); value = value.replace(',', '');
        value = value.replace(',', ''); value = value.replace(',', ''); value = value.replace(',', '');
        let x = value.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1))
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        return x1 + x2;
    }
    handleKeyUp(e) { 
        if(e.target.pattern == "number"){   
             var charCode = e.which ? e.which : e.keyCode;        
            if(!this.isNumber(charCode)) {
                e.target.value = e.target.value.replace(String.fromCharCode(charCode),"")
                e.target.value = e.target.value.replace(String.fromCharCode(charCode).toLowerCase(),"")
                return
            }
        }
        if(e.target.pattern == "number"){   
            e.target.value = this.addComma(e.target.value)
        }
        if (e.target.value.length < 1) {
            e.target.value = '$';
        } else if (e.target.value.indexOf('$') !== 0) {
            e.target.value = '$' + String.fromCharCode(e.which);
        }
    }

    renderhtml() {
        var list = [];
        this.state.form_property.map((el, index) => {
            console.log('render html ', el)
            list.push(
                <div key={index.toString()}>
                    <div className="form-group col-md-12">
                        <label for="add_liability_1" className="sub_label">Add liability {el.count}</label>
                        <select name="liability" className="form-control" value={el.liability} onChange={e => this.handleTextChange(e.target.value, index, "liability")}>
                            <option value=''>-Select liability type-</option>
                            <option value='Credit card'>Credit card</option>
                            <option value='Store card'>Store card</option>
                            <option value='Personal loan (not included in assets)'>Personal loan (not included in assets)</option>
                            <option value='Hire purchase'>Hire purchase</option>
                            <option value='HECS or Student loan'>HECS or Student loan</option>
                            <option value='Other liability'>Other liability</option>
                        </select>
                    </div>
                    {(el.liability !== '') ? (<>
                        <div className="form-group col-md-12">
                            <label className="sub_label" for="liability_description">Liability 1 description (inc financial institution name) <span className="text-danger">*</span>
                            </label>
                            <input type="text" name="liability_description" value={el.liability_description} className="form-control" onChange={e => this.handleTextChange(e.target.value, index, "liability_description")} required />
                            {(el.liability_description_error !== '') ? (<><span className="text-danger">{el.liability_description_error}</span></>) : null}
                        </div>
                        <div className="form-group col-md-12">
                            <label className="sub_label" for="amount_owed_on_liability">Amount owed on liability 1 <span className="text-danger">*</span> </label>
                            <input type="text" pattern="number" onKeyUp={e => {return this.handleKeyUp(e)}}  value={el.amount_owed_on_liability} name="amount_owed_on_liability" onChange={e => this.handleTextChange(e.target.value, index, "amount_owed_on_liability")} className="form-control text-right" required />
                            {(el.amount_owed_on_liability_error !== '') ? (<><span className="text-danger">{el.amount_owed_on_liability_error}</span></>) : null}
                        </div>
                        {(el.liability == 'Credit card' || el.liability == 'Store card') ?
                            <div className="form-group col-md-12">
                                <label className="sub_label" for="credit_limit_on_liability">Credit limit on liability 1  <span className="text-danger">*</span> </label>
                                <input type="text" pattern="number" onKeyUp={e => {return this.handleKeyUp(e)}} value={el.credit_limit_on_liability} name="credit_limit_on_liability" className="form-control text-right" onChange={e => this.handleTextChange(e.target.value, index, "credit_limit_on_liability")} required />
                                {(el.credit_limit_on_liability_error !== '') ? (<><span className="text-danger">{el.credit_limit_on_liability_error}</span></>) : null}

                            </div> : null}
                        <div className="form-group col-md-12">
                            <label className="sub_label" for="monthly_replayment_liability"> Monthly repayments on liability 1 <span className="text-danger">*</span> </label>
                            <input type="text"  pattern="number" onKeyUp={e => {return this.handleKeyUp(e)}} value={el.monthly_replayment_liability} name="monthly_replayment_liability" className="form-control text-right" onChange={e => this.handleTextChange(e.target.value, index, "monthly_replayment_liability")} required />
                            {(el.monthly_replayment_liability_error !== '') ? (<><span className="text-danger">{el.monthly_replayment_liability_error}</span></>) : null}

                        </div>
                        <div className="form-group col-md-12">
                            <label className="sub_label" for="add_liability_1_monthly_repayment">Attach liability 1 statement or document (if provided)</label>
                            <div style={{ height: "100px" }} className="d-flex align-items-center justify-content-center border ">
                                <Dropzone onDrop={e => this.fileDrop(e, index, "property_filename")}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {el.property_filename ? (<div>{el.property_filename}</div>):<p>Drag 'n' drop some files here, or click to select files</p>}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>


                    </>) : <div>
                            {(index != 0) ?
                                <div>
                                    <div className="form-group col-md-12">
                                        <label className="sub_label" for="liability_description">Liability 1 description (inc financial institution name) <span className="text-danger">*</span>
                                        </label>
                                        <input value={el.liability_description} type="text" name="liability_description" className="form-control" onChange={e => this.handleTextChange(e.target.value, index, "liability_description")} required />
                                        {(el.liability_description_error !== '') ? (<><span className="text-danger">{el.liability_description_error}</span></>) : null}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="sub_label" for="amount_owed_on_liability">Amount owed on liability 1 <span className="text-danger">*</span> </label>
                                        <input value={el.amount_owed_on_liability} type="text"  pattern="number" onKeyUp={e => {return this.handleKeyUp(e)}} name="amount_owed_on_liability" onChange={e => this.handleTextChange(e.target.value, index, "amount_owed_on_liability")} className="form-control text-right" required />
                                        {(el.amount_owed_on_liability_error !== '') ? (<><span className="text-danger">{el.amount_owed_on_liability_error}</span></>) : null}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="sub_label" for="monthly_replayment_liability"> Monthly repayments on liability 1 <span className="text-danger">*</span> </label>
                                        <input type="text" name="monthly_replayment_liability"   pattern="number" onKeyUp={e => {return this.handleKeyUp(e)}}  className="form-control text-right" onChange={e => this.handleTextChange(e.target.value, index, "monthly_replayment_liability")} required />
                                        {(el.monthly_replayment_liability_error !== '') ? (<><span className="text-danger">{el.monthly_replayment_liability_error}</span></>) : null}

                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="sub_label" for="add_liability_1_monthly_repayment">Attach liability 1 statement or document (if provided)</label>
                                        <div style={{ height: "100px" }} className="d-flex align-items-center justify-content-center border ">
                                            <Dropzone onDrop={e => this.fileDrop(e, index, "property_filename")}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {el.property_filename ? (<div>{el.property_filename}</div>):<p>Drag 'n' drop some files here, or click to select files</p>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div> : null}
                        </div>}
                    {(index < 3) ? (<>
                        <div className="from-group">
                            <button className="btn add_another_button" onClick={e => this.addAnother(el, index)}>Add Another</button>
                        </div>
                    </>) : null}
                </div>)
        });
        return list;
    }

    onSecondFromSubmit() {
        let formIsValid = true;

        var form_data = this.state.form_property;

        Object.keys(form_data).map((key, i) => {
            if (form_data[i]["liability"] !== '') {
                if (form_data[i]["liability_description"] === '') {
                    form_data[i]["liability_description_error"] = "This field is required";
                    formIsValid = false;
                }
                if (form_data[i]["amount_owed_on_liability"] === '') {
                    form_data[i]["amount_owed_on_liability_error"] = "This field is required";
                    formIsValid = false;
                }
                if (form_data[i]["credit_limit_on_liability"] === '') {
                    form_data[i]["credit_limit_on_liability_error"] = "This field is required";
                    formIsValid = false;
                }
                if (form_data[i]["monthly_replayment_liability"] === '') {
                    form_data[i]["monthly_replayment_liability_error"] = "This field is required";
                    formIsValid = false;
                }
            }

        })

        this.setState({ form_property: form_data });

        if (formIsValid) {
            this.props.onNextButtonClick(this.state);
        }

    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Add other liabilities</h4>
                        </div>
                    </div>


                    {this.state.form_property.length > 0 ? this.renderhtml() : null}


                    <div className="row">

                        <div className="form-group">
                            <br />
                            <br />
                            {this.props.details.current_step > 1 ? (<button className="btn btn-previous  float-left" onClick={() => this.props.onPreviousButtonClick()}>Previous</button>) : null}
                            {this.props.details.current_step < 5 ? (<button className="btn btn-next  float-right ml-2" onClick={() => this.onSecondFromSubmit(this.state)} >Next</button>) : null}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default Step3