import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import MenuIcon from '@material-ui/icons/Menu';
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";
import CardMedia from "@material-ui/core/CardMedia";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";

const style = theme => ({
  svgclick:{
      height: "30px",
      left: "-31px",
      position: "relative",
      "fill":"#fff",
      "top":"-4px",
      "fillRule":"evenodd"
    },
    logo:{
      width:"77px",
      height:"24px"
    }
})
const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(style);

export default function Header(props) {
  const classes = useStyles();
  const classes1 = useStyles1();
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  return (
   <div className={classes.root}> 
    <AppBar position="fixed">
      <Toolbar>
      <Hidden mdDown implementation="css">
      <IconButton edge="start" onClick={props.handleDrawerOpenClose} className={classes1.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          </Hidden> 
        <div className={classes.flex} style={{"color":"white","fontSize":"25px","fontWeight":600}}>

          <img className={classes1.logo} src="/Amili-Icon.png"></img>
    
        </div>
        {
          !props.hideLinks?
          <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
        </Hidden>:""}
        {
          !props.hideLinks? <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>:""}
      </Toolbar>
    </AppBar>
    </div>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
