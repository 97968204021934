import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { API } from "aws-amplify";
import { connect } from 'react-redux'
import Dialogs from "components/Dialog/Dialogs.js";
import EditModule from "views/Modules/EditModule.js";
import FormBuilder from "views/Forms/FormBuilder.js"
import { useForm } from 'react-hook-form';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  rountedBtn: {
    borderRadius: "55px",
    margin: "2px"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);
const mapStatetoProps = state => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

function Modules(props) {
  const classes = useStyles();
  const [avModules, setAvModules] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [modules, setModules] = useState(false);
  const { register, errors } = useForm();
  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  const handleSave = () => {
    setOpenDialog(false)
  }
  const viewModules = module => {
    setOpenDialog(true)
    //module.forms = module.values
    setModules(module)
  }
  useEffect(() => {
    if (!props.idToken) return
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
    };
    API.get("Nimo-Service", "module", myInit).then((response) => {
      setAvModules(response.items);
    });
  }, [props.idToken])
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>Available Modules</h4>
            </CardHeader>
            <CardBody>
              <List>
                {avModules.map((form, i) => (
                  <ListItem
                    divider={i < avModules.length - 1}
                    key={i}
                  >
                    <ListItemText
                      primary={form.name}
                    />
                    <ListItemSecondaryAction>
                      <Button color="primary" size="small" onClick={(e) => { viewModules(form, i) }} variant="outlined" className={classes.rountedBtn}>View</Button>
                    </ListItemSecondaryAction>

                  </ListItem>
                ))}
              </List>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>     
       <Dialogs title={modules.name} isEdit={false} content={<FormBuilder
        register={register}
        readMode={true}
        verifyMail={() => { }}
        resend={() => { }}
        errors={errors}
        data={modules}
      />} handleSave={handleSave} open={openDialog} handleClose={handleDialogClose} />

    </>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(Modules)