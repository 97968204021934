
import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputArea from "components/CustomInput/CustomInputArea.js";
import CustomSelect from "components/FormsControl/CustomSelect.js";
import CustomizedRadios from "components/FormsControl/CustomizedRadios.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Paper from '@material-ui/core/Paper';
import { useForm } from 'react-hook-form';

import {
  Box,
  Divider,
  IconButton,
  List,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
const FormBuilder = (props) => {

  const [values, setValues] = useState([]);
  const { register, errors } = props;

  useEffect(() => {
    if (props.data && props.data.forms) {
      setValues(props.data.forms)
    }
    if(props.data && props.data.values){
      setValues(props.data.values)
    }
  }, [props])

  const constructElements = (item, index) => {
    switch (item.type && item.type.toLowerCase()) {
      case "header":
        if (item.subtype == "h1") {
          return <h1>{item.label}</h1>
        }
      break;
      case "label":
        return (<InputLabel htmlFor="component-helper">{item.label}</InputLabel>)
      break;
      case "text":
        return (
          <>
            <CustomInput
              name={item.name}
              labelText={item.label}              
              key={index + "-text"}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: item.type,
                required:item.required,
                inputRef: register({ required: item.required }),
                name: item.name,
                value: item.value,
                onChange: (e) => {
                  e.persist()
                  setValues(preValues => {
                    preValues.forEach((val, index) => {
                      if (e.target && val.name == e.target.name) {
                        preValues[index].value = e.target.value
                      }
                    })
                    return [...preValues];
                  });
                }
              }}
            />
          </>
        )
      break;
      case "date":
        return (
          <>
            <CustomInput
              name={item.name}
              labelText={item.label}
              key={index + "-date"}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: item.type,
                required:item.required,
                inputRef: register({ required: item.required }),
                name: item.name,
                value: item.value,
                onChange: (e) => {
                  e.persist()
                  setValues(preValues => {
                    preValues.forEach((val, index) => {
                      if (e.target && val.name == e.target.name) {
                        preValues[index].value = e.target.value
                      }
                    })
                    return [...preValues];
                  });
                }
              }}
            />
          </>
        )
      break;
      case "input-group":
        return (
          <>
            <Box display="flex">
              <GridItem xs={12} lg={6}>
                <CustomInput
                  name={item.name}
                  labelText={item.label}
                  key={index + "-input-group"}
                  formControlProps={{
                    name: item.name,
                  }}
                  inputProps={{
                    type: item.inputType,
                    required:item.required,
                    name: item.name,
                    disabled: item.emailVerified,
                    placeholder: item.placeholder,
                    inputRef: register({ required: item.required, minLength: item.minLength }),
                    value: item.value,
                    onChange: (e) => {
                      e.persist()
                      setValues(preValues => {
                        preValues.forEach((val, index) => {
                          if (e.target && val.name == e.target.name) {
                            preValues[index].value = e.target.value
                          }
                        })
                        return [...preValues];
                      });
                    }
                  }}
                />

                {item.verifyRequired && !item.emailVerified && !props.readMode? (
                  <Button color="primary" size="small" variant="outlined" onClick={(e) => { props.verifyMail(item.verifyRequired) }}>{item.verifyRequired.label}</Button>
                ) : null}
                {item.emailVerified && !props.readMode? (
                  <Button color="primary" size="small" variant="outlined" onClick={(e) => { props.resend(item.verifyRequired) }}>Resend</Button>
                ) : null}
              </GridItem>
            </Box>
          </>
        )
      break;
      case "textarea":
        return (
          <>
            <CustomInput
              name={item.name}
              labelText={item.label}
              key={index + "-textarea"}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: item.type,
                name: item.name,
                required:item.required,
                inputRef: register({ required: item.required }),
                value: item.value,
                onChange: (e) => {
                  e.persist()
                  setValues(preValues => {
                    preValues.forEach((val, index) => {
                      if (e.target && val.name == e.target.name) {
                        preValues[index].value = e.target.value
                      }
                    })
                    return [...preValues];
                  });
                }
              }}
            />
          </>
        )
        break;
      case "select":
        return (
          <>
            <CustomSelect
              name={item.name}
              labelText={item.label}
              key={index + "-select"}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: item.type,
                options: item.options,
                required:item.required, 
                name: item.name,
                value: item.value,
                onChange: (e) => {
                  e.persist()
                  setValues(preValues => {
                    preValues.forEach((val, index) => {
                      if (e.target && val.name == e.target.name) {
                        preValues[index].value = e.target.value
                      }
                    })
                    return [...preValues];
                  });
                }

              }}
            />
          </>
        )
        break;
      case "radio":
        return (
          <>
            <CustomizedRadios
              name={item.name}
              labelText={item.label}
              key={index + "-radio"}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                options: item.options, 
                name: item.name,
                value: item.value,
                required:item.required,
                onChange: (e) => {
                  e.persist()
                  setValues(preValues => {
                    preValues.forEach((val, index) => {
                      if (e.target && val.name == e.target.name) {
                        preValues[index].value = e.target.value
                      }
                    })
                    return [...preValues];
                  });
                }

              }}
            />
          </>
        )
        break;
    }
  }

  const renderFormElements = (item, index) => {
    return (
      <>
        {constructElements(item, index)}
      </>)
  }
  return (
    <>

      <div>
        <GridContainer>

         {
            values && values.map((item,index)=>{
              return (<>
                {item.visible? <GridItem xs={12} lg={props.view?props.view:(values.length==1 && props.readMode?12:6)} style={{"width":"300px"}} key={"col"+index} className="mt-4">
                 {renderFormElements(item,index)}
              </GridItem>:null}</>)    
            })
            
          }
        </GridContainer>

      </div>

    </>
  );

}

const mapStateToProps = state => {
  return {
  }
}
const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
