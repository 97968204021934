import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialogs from "components/Dialog/Dialogs.js";
import CardFooter from "components/Card/CardFooter.js";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { API } from "aws-amplify";
import { connect } from 'react-redux'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import { ReactSortable } from "react-sortablejs";
import FormBuilder from "views/Forms/FormBuilder.js"
import { useForm } from 'react-hook-form';
import EditModule from "views/Modules/EditModule.js";
import { actions } from "actions"
import {
  Box,
  Divider,
  IconButton,
  List,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
const data = [
  {

    name: 'User Verify'
  },
  {

    name: 'User Verify1'
  }
];
const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  rountedBtn: {
    borderRadius: "55px",
    margin: "2px"
  },
  ht: {
    minHeight: "250px"
  }
});

const useStyles = makeStyles(styles);
const mapStatetoProps = state => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    }
  }
}

const createForms = (name, acModules, isPublished, props, history) => {
  let steppers = [];
  let formData = {
    name: name,
    status: !isPublished ? "Available" : "Published",
    values: {
      stepper: true,
      steppers: steppers,
    },
  };
  acModules.forEach((mactive, index) => {
    let step = {};
    if (mactive.values) {
      step.stepId = mactive.name;
      step.title = mactive.name;
      step.progress = index + 1;
      step.data = { forms: mactive.values };
      steppers.push(step);
      mactive.values.forEach((ma) => {
         ma.visible = ma.visible
         ma.required = ma.required
      });
    }
  });
  const myInit = {
    headers: {
      "X-COG-KEY": props.idToken,
    },
    body: { ...formData },
  };
  API.post("Nimo-Service", "customerform", myInit)
    .then((response) => {
      props.showToaster("Form has been created successfully", "success")
      history.push("/nimo/forms")
    })
    .catch((err) => {
      props.showToaster("Failed in creating form", "error")
    });
}

function CustomerForms(props) {
  const classes = useStyles();
  const [avModules, setAvModules] = useState([]);
  const [acModules, setAcModules] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [openDialog, setOpenDialog] = useState(false);
  const [modules, setModules] = useState(false);
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const history = useHistory();
  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  const handleSave = () => {
    setOpenDialog(false)
    console.log(modules)
    acModules.forEach((aM) => {
      if (aM.name == modules.name) {
        aM.values = modules.values
      }
    })
    setAcModules(acModules)
  }
  const viewModules = module => {
    setOpenDialog(true)
    setIsView(true)
    setIsEdit(false)
    setModules(module)
  }
  const addModule = (module, index) => {
    let modules = [...acModules, ...[avModules.splice(index, 1)[0]]]
    setAcModules(modules)

  }
  const removeModule = (module, index) => {
    let modules = [...avModules, ...[acModules.splice(index, 1)[0]]]
    setAvModules(modules)
  }
  const editModule = (module, index) => {
    setOpenDialog(true)
    setIsEdit(false)
    setIsView(false)
    setModules(module)
  }
  const saveForms = (isPublished) => {
    if(!name){
      props.showToaster("Please provide form name", "error")
      return;
    }
    createForms(name, acModules, isPublished, props, history)
  }
  useEffect(() => {
    if (props.idToken) {
      const myInit = {
        headers: {
          "X-COG-KEY": props.idToken,
        },
      };
      API.get("Nimo-Service", "module", myInit).then((response) => {
        setAvModules(response.items);
      });
    }
  }, [props.idToken])
  return (
    <>
      <GridContainer>

        <GridItem xs={12} sm={12} md={6}>
          <h4>Create Forms</h4>
          <Card>
            <CardBody>
              <CustomInput
                labelText="Form Name"
                id="cname"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: name,
                  onChange: (e) => {
                    setName(e.target.value)
                  }
                }}
              />

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>Available Modules</h4>
            </CardHeader>
            <CardBody style={{ 'height': '250px' }}>

              <List>
                <ReactSortable
                  list={avModules}
                  group="modules"
                  height="250px"
                  animation={200}
                  setList={setAvModules}
                  className={classes.ht}
                >
                  {avModules.map((module, i) => (

                    <ListItem
                      divider={i < avModules.length - 1}
                      key={module.name}
                    ><IconButton
                      edge="end"
                      size="small"
                      className="handle"
                    >
                        <MoreVertIcon />
                      </IconButton>

                      <ListItemText
                        primary={module.name}
                      />
                      <ListItemSecondaryAction>
                        <Button color="primary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { viewModules(module, i) }}>View</Button>
                        <Button color="primary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { addModule(module, i) }}>Add</Button>
                      </ListItemSecondaryAction>

                    </ListItem>

                  ))}
                </ReactSortable>
              </List>
            </CardBody>
            <CardFooter>

            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <Card style={{ 'height': '310px' }}>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>
                Active Modules
            </h4>
            </CardHeader>
            <CardBody>
              <List>
                <ReactSortable
                  list={acModules}
                  group="modules"
                  setList={setAcModules}
                  multiDrag
                  animation={200}
                  className={classes.ht}
                >
                  {acModules.map((module, i) => (
                    <ListItem
                      divider={i < acModules.length - 1}
                      key={module.name}
                    ><IconButton
                      edge="end"
                      size="small"
                      className="handle"
                    >
                        <MoreVertIcon />
                      </IconButton>

                      <ListItemText
                        primary={module.name}
                      />
                      <ListItemSecondaryAction>
                        <Button color="primary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { editModule(module, i) }}>Edit</Button>
                        <Button color="secondary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { removeModule(module, i) }}>Delete</Button>
                      </ListItemSecondaryAction>

                    </ListItem>
                  ))}
                </ReactSortable>
              </List>
            </CardBody>

          </Card>
        </GridItem>

      </GridContainer>
      <GridContainer>
        <Box m={2} pt={3}>
          <Button color="primary" variant="contained" onClick={() => { saveForms(false) }} startIcon={<SaveIcon />}>Save</Button>
        </Box>
        <Box m={2} pt={3}>
          <Button color="primary" variant="contained" onClick={() => { saveForms(true) }} startIcon={<CloudUploadIcon />}>Save & Publish</Button>
        </Box>
      </GridContainer>
      {!isView ? <Dialogs title={modules.name} isEdit={isEdit} content={<EditModule data={modules} />} handleSave={handleSave} open={openDialog} handleClose={handleDialogClose} /> : ""}
      {isView ? <Dialogs title={modules.name} isEdit={false} content={<FormBuilder
        register={register}
        readMode={true}
        verifyMail={() => { }}
        resend={() => { }}
        errors={errors}
        data={modules}
      />} handleSave={handleSave} open={openDialog} handleClose={handleDialogClose} /> : null}

    </>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(CustomerForms)