import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardIcon from "components/Card/CardIcon.js";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js"; 
import MaterialTable, { MTableToolbar } from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from '@material-ui/core/Switch';
import Icon from "@material-ui/core/Icon";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Print from "@material-ui/icons/Print";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import { actions } from "actions";
import ReactToPrint from "react-to-print";

import { Auth, API } from "aws-amplify";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(50),
      height: theme.spacing(25),
    },
  },
  btnActions:{
    float: "right",
    bottom: 0,
    position: "relative",
    top: "-9%"
  },
  switchAction:{
      bottom: 0,
      margin: "10px",
      position: "relative",
      top: "16%"
  },
  spacing10:{
    margin: theme.spacing(1)
  }
}));

const mapStatetoProps = (state) => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      isLoader: state.commonReducers.isLoader,
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType));
    },
    showLoader: (msg) => {
      dispatch(actions.showLoader(msg));
    },
    hideLoader: () => {
      dispatch(actions.hideLoader());
    }
  };
};

function BarCodeComp(props) {
  const history = useHistory();
  let componentRef = []
  const [selectedRow, setSelectedRow] = useState(null);
  const [kitIDs, setKitIDs] = useState([]);
  const [itemToDelete, setItemToDelete] = useState({})
  const [isDeleteProgress, setDeleteProgress] = useState(false)
  const [progress, setProgress] = useState(false)
  const [dispalyAlert, setDisplayAlert] = useState(false)
  const [sendCollectionKit, setSendCollectionKit] = useState(false)

  const getSelectedKitIds = (items)=>{
      items = items.filter((item)=>{
        return item.kitStatus ==1;
      })
      return items
  }
  const confirmTodelete = (item) => {
    setItemToDelete(item);
    setDisplayAlert(true);
  }
  const updateCollectionStatus = (e,rowData)=>{ 
      setSendCollectionKit(e.target.checked)
      updateItem(rowData,e.target.checked)
  }
  const updateItem = (item, status) => {
    item = JSON.parse(JSON.stringify(item))
    if(status){
      item.kitStatus = 2
    }else{
      item.kitStatus = 1
    }
    delete item.tableData
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: { ...item },
    };
    API.put("Amili-Service", "", myInit)
      .then((response) => {        
        props.showToaster("Status updated successfully")
        if(response.items){          
          setKitIDs(getSelectedKitIds(response.items))
        }
      })
      .catch((err) => {
        props.showToaster("Error in sendCollection"+err)
       });
  }

  const deleteItem = ()=> {
    setDisplayAlert(false)
    setDeleteProgress(true)
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        SORTID: itemToDelete.SORTID,
      },
    };
    API.del("Amili-Service", "", myInit)
      .then((response) => {
          setItemToDelete({})
          setDeleteProgress(false)
          props.showToaster("KitID has been removed successfully","success")
          if(response.items){          
            setKitIDs(getSelectedKitIds(response.items))
          }
      })
      .catch((err) => {
        setDeleteProgress(false)
        props.showToaster("Failed in remove KitID"+err,"error")
      });
  }
  const getKitIDs = () => {
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        ID: "KITID"
      },
    };
    API.get("Amili-Service", "", myInit)
      .then((response) => { 
        if(response.items && response.items.length){          
          setKitIDs(getSelectedKitIds(response.items))
        }
        props.hideLoader()
      })
      .catch((err) => {
        props.showToaster("Failed in get kitIDs "+err,"error")
      });
   };
   const createKitID = () => {
    const formData = {
        
    }
    setProgress(true)
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
       
      body: { ...formData }
    };
    API.post("Amili-Service", "", myInit)
      .then((response) => { 
        setProgress(false)
        props.showToaster("Kit ID has been created successfully","success")
        if(response.items && response.items.length){          
         setKitIDs(getSelectedKitIds(response.items))
        }
      })
      .catch((err) => {
        setProgress(false)
        props.showToaster("Failed in create Kit ID "+err,"error")
      });
   }
  useEffect(() => {
    if (!props.idToken) return;
    props.showLoader()
    getKitIDs(props);
  }, [props.idToken]);
  const classes = useStyles(); 
  const calcTimeZone = (time,offset)=>{
    return new Date(time).toLocaleString("en-US", {timeZone: "Singapore"})
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        
        <Card>
            <CardHeader plain color="warning">
              <Box display="flex">
                <Box flexGrow={1}>
                  <h4 className={classes.cardTitleWhite}>Create Kit IDs</h4>
                </Box>
                <Box>
                  <Fab size="small" color="primary" aria-label="add" disabled={progress}>
                    <AddIcon onClick={createKitID}/>
                  </Fab>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              {
                props.isLoader ? <Skeleton animation="wave" variant="rect" width={"auto"} height={300} />:
<MaterialTable
                title=""

                columns={[
                  { title: 'Kit ID',defaultSort:"desc",cellStyle: {
       width: '20%'
     }, field: 'kitID', render: rowData => {
                      return (
                        <div>  
                          <div> 
                            <Barcode fontSize={15} lineColor={"#990000"} height={20} width={1} marginRight={4} value={rowData.kitID} />
                            <div style={{"visibility":"hidden","height":"0px","width":"30%"}}><Barcode  height={30} ref={(el) => (componentRef[rowData.kitID] = el)}  marginRight={4} value={rowData.kitID.split("AML_")[1]} /></div>
                          </div>
                        </div>
                      )
                    } }, 
                  {
                    title: 'Status', field: 'status'
                  },
                  {
                    title: 'Created on', field: 'statusHistory', render: rowData => {
                      return (
                        <div>
                          {rowData.statusHistory[rowData.kitStatus]?.date?rowData.statusHistory[rowData.kitStatus]?.date:""}
                           
                        </div>)
                    }
                  },
                  
                  {
                    title: 'Actions', field: 'actions', 'align': 'left', render: rowData => {
                      return (
                        <div
                        >
                          <ReactToPrint
                            trigger={() =>  <Print style={{ 'cursor': 'pointer' }}/>}
                            content={() => componentRef[rowData.kitID]}
                          />
                         
                          {rowData.kitStatus && rowData.kitStatus!=2 && <Delete style={{ 'cursor': 'pointer', color: "red" }} onClick={(event) =>confirmTodelete(rowData)} />}
                           {isDeleteProgress && rowData.SORTID == itemToDelete.SORTID?<CircularProgress color="secondary" size={24} className={classes.buttonProgress} />:""}
                        </div>)
                    }
                  },
                  {
                    title: 'Sent for Sample Collection', field: 'sendCollectionKit', render: rowData => {
                      return (
                        <div> 
                           <Switch
                              checked={rowData.kitStatus==2?true:false} 
                              name="collectionKit"
                              onChange={(e)=>{updateCollectionStatus(e,rowData)}}
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            /> 
                        </div>
                      )
                    }
                  }
                ]}
                data={kitIDs}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                  sorting: true,
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                  })
                }}
              />
            }
             
            </CardBody>

          </Card>

          
        </GridItem>
      </GridContainer>
      <ConfirmModal open={dispalyAlert} msg="Are you sure you want to delete this KIT ID?" confirm={deleteItem} cancel={() => { setDisplayAlert(false) }} />         
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(BarCodeComp);
