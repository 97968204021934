const formBuilderReducer = (state = [], action) => {	
	switch (action.type) {    
  case 'SET_USER_FORM':
      return {
          ...state,...action
        }
      break;	

  case 'SET_CURRENT_STEP':
      return {
          ...state,...action
        }
      break;  

  case 'SET_STEPS':
      return {
          ...state,...action
        }
      break;  

   case 'SET_CUSTOMER_FORM':
      return {
          ...state,...action
        }
      break; 

    case 'SET_STEP_INDEX':
      return {
          ...state,...action
        }
      break;  

    default:
      return state
  }
}

export default formBuilderReducer