import {routes,barRoutes,uploadLabRoutes,uploadQualtricsRoutes,qcRoutes}  from "routes.js";
export const findRoutes = (group)=>{
	 let sideBarRoutes = []
	 if(group && group.toString().indexOf("Lab-barcode")!=-1){
      		sideBarRoutes = [...barRoutes]
	  }
	  if(group && group.toString().indexOf("S3-Lab")!=-1){
	      sideBarRoutes = [...sideBarRoutes,...uploadLabRoutes]
	  }
	  if(group && group.toString().indexOf("S3-Qualtrics")!=-1){
	      sideBarRoutes = [...sideBarRoutes,...uploadQualtricsRoutes]
	  }
	  
	  if(group && group.toString().indexOf("Medical-affairs")!=-1){
	      sideBarRoutes = [...sideBarRoutes,...qcRoutes]
	  }
	  sideBarRoutes = [...sideBarRoutes,...routes]
	  return sideBarRoutes;
}