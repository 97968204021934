import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import "react-step-progress-bar/styles.css";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { ProgressBar, Step } from "react-step-progress-bar";
import { withRouter } from 'react-router-dom';
import { API } from "aws-amplify";
// import Step_One from "../Components/step_one.js";
import Step1 from "./Step1.js";
import Step2 from "./Step2.js";
import Step3 from "./Step3.js";
import Step4 from "./Step4.js";
import Step5 from "./Step5.js";
import Step6 from "./Step6.js";

import { connect } from "react-redux";
import { actions } from "actions"

const mapStatetoProps = (state) => {
  if (state?.userformReducer?.customerForm) {
    return {
      userForm: state.userformReducer.userForm,
      steps: state.userformReducer.steps,
      stepId: state.userformReducer.stepId,
      customerForm: state.userformReducer.customerForm,
      stepIndex: state.userformReducer.stepIndex
    }
  }
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return { 
      setUserForm: (userform) => { 
        dispatch(actions.setUserForm(userform))
      }
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 20,
      current_step: 1,
      totalSteps:5,
      //step 1
      email: '',
      email_error: '',
      steps:{},
      //step 2
      step_2_form: {}

    }
    this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
    this.onNextButtonClick = this.onNextButtonClick.bind(this);
    this.onEmailChangeValidation = this.onEmailChangeValidation.bind(this);
    this.onSecondFromSubmit = this.onSecondFromSubmit.bind(this); 
  }

  onPreviousButtonClick = () => {
    var current_page_step = this.state.current_step;
    var progress = this.state.progress;
    progress = progress - 20;
    current_page_step--;
    this.setState({
      current_step: current_page_step
    })
    this.setState({
      progress: progress
    })

  } 
  componentWillMount(){    
      let props = this.props
      if(props.customerForm && props.steps){
        this.setState({
          steps:props.steps?.find(r=>r.stepId == props.stepId),
          totalSteps:props.customerForm?.values?.steppers.find(s=>s.stepId === props.stepId)?.data?.forms?.filter((f)=>{ return f.visible==true}).length
        },()=>{
          if(props.userForm){
            let userForm = props.userForm
            if(userForm.values && props.stepId){
              userForm = userForm.values[props.stepId]        
            }    
            if(userForm){
              this.setState({
                current_step:(this.state.totalSteps == Object.keys(userForm).length)?Object.keys(userForm).length:Object.keys(userForm).length+1,
                progress:(userForm?Object.keys(userForm).length+1:1)*20
              })      
            }         
            
          }
        })
      }

      
  }    
  componentWillReceiveProps(props){ 
    if(props.stepId != this.props.stepId){      
      if(props.customerForm && props.steps){
        this.setState({
          steps:props.steps?.find(r=>r.stepId == props.stepId),
          totalSteps:props.customerForm?.values?.steppers.find(s=>s.stepId === props.stepId)?.data?.forms?.filter((f)=>{ return f.visible==true}).length
        },()=>{
             if(props.userForm){
                let userForm = props.userForm
                if(userForm.values && props.stepId){
                  userForm = userForm.values[props.stepId]        
                } 
                if(userForm){
                  this.setState({
                    current_step:(this.state.totalSteps == Object.keys(userForm).length)?Object.keys(userForm).length:Object.keys(userForm).length+1,
                    progress:(userForm?Object.keys(userForm).length+1:1)*20
                  }) 
                }
             }
             
        })
      }
     
    }    
  }

  apiRequest = (data,isCompleted) => {
    let method = "post";
    let values = {}    
    if(this.props.userForm && this.props.userForm.values){      
      values = {...this.props.userForm.values}
    }
    if(!values[this.props.stepId]){
      values[this.props.stepId] = {}
    }
    values[this.props.stepId][data.id] = data
    let datas = {values};
    datas.customerID = this.props.customerForm.createdByGroup;
    datas.customerformId = this.props.customerForm.SID.split("#")[0];
    datas.financials = this.state.current_step == this.state.totalSteps?true:"inprogress"
    if(this.props.userForm && this.props.userForm.SID){
      method = "put"      
      datas.SID = this.props.userForm.SID;
    }
    /*if(isCompleted){
      datas.financials = isCompleted
    }*/ 
    this.props.setUserForm(datas)
    if(this.state.current_step == this.state.totalSteps){
      this.props.handleExternalNext(datas)
    }else{
      this.props.handleAPIrequest(method,datas) 
    }   

  };

  onEmailChangeValidation = (email) => {

    this.setState({
      email: email
    })

    if (email != '') {
      this.setState({
        email_error: '',
      });
    }
    else {
      this.setState({
        email_error: 'Please enter email',
        step_1_validation: false
      });
    }


  }

  onSecondFromSubmit = (data,isCompleted) => {

    var current_page_step = this.state.current_step;
    var progress = this.state.progress;
    progress = progress + 20;
    current_page_step++;
    this.setState({
      current_step: current_page_step
    })
    this.setState({
      progress: progress
    })
    this.apiRequest(data,isCompleted)
  }

  onNextButtonClick = (data,isCompleted) => {

    var current_page_step = this.state.current_step;
    var progress = this.state.progress;

    progress = progress + 20;
    current_page_step++;
    this.setState({
      current_step: current_page_step
    })
    this.setState({
      progress: progress
    }) 
    this.apiRequest(data,isCompleted) 
    
  }

  onSecondStepNextButtonClick = () => {

  }
 

  render() {
    let { current_step,steps,totalSteps } = this.state;
    let originalstep = current_step
    if(!this.props.userForm) return null
    let {userForm} = this.props
    if(userForm.values && this.props.stepId){
      userForm = userForm.values[this.props.stepId]        
    }
    let count = 0; 
    let assetForm = steps?.userForm?.forms.find((d)=> {return d.name=="Asset"})
    if(assetForm && !assetForm.visible){
        current_step = originalstep+1
    }else{
      count++
    }
    let liabilityForm = steps?.userForm?.forms.find((d)=> {return d.name=="Liability"})
    if(liabilityForm && !liabilityForm.visible){
        current_step = originalstep+2
    }else{
      count++
    }
    let employeeForm = steps?.userForm?.forms.find((d)=> {return d.name=="Employee"})
    if(employeeForm && !employeeForm.visible){
        current_step = originalstep+3
    }else{
      count++
    }
    let expensesForm = steps?.userForm?.forms.find((d)=> {return d.name=="Expenses"})
    if(expensesForm && !expensesForm.visible){
        current_step = originalstep+4
    }else{
      count++
    }
    let notesForm = steps?.userForm?.forms.find((d)=> {return d.name=="Notes"})
    if(notesForm && !notesForm.visible){
        current_step = originalstep+5
    }else{
      count++
    }
    //totalSteps = count

    var onEmailChangeValidation = this.onEmailChangeValidation;
    var onSecondFromSubmit = this.onSecondFromSubmit;
    var onNextButtonClick = this.onNextButtonClick;
    var onPreviousButtonClick = this.onPreviousButtonClick;

    return (<> 
      <Card>
        <CardBody>
        <br />
        {current_step < totalSteps+1 ?
        <>
         <h3 className="font-weight-bold">Add your financials!</h3> 
          <br />
         
         <strong>Step {this.state.current_step} of {totalSteps}</strong>
        <ProgressBar
          percent={this.state.progress}
          filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        >
          {/* <Step transition="scale">
            {({ accomplished }) => (<>

            </>
            )}
          </Step> */}
          <Step transition="scale">
            {({ accomplished }) => (<>

            </>

            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (<>

            </>

            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (<>

            </>

            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (<>

            </>

            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (<>

            </>

            )}
          </Step>
        </ProgressBar></>:""}
        <br />        
        {current_step == 1  && current_step<totalSteps+1 ? (<Step2 data={userForm?.asset} details={this.state} onNextButtonClick={onNextButtonClick.bind(this)} onPreviousButtonClick={onPreviousButtonClick.bind(this)} />) : null}
        {current_step == 2 && current_step<totalSteps+1 ? (<Step3 data={userForm?.liability} details={this.state} onNextButtonClick={onNextButtonClick.bind(this)} onPreviousButtonClick={onPreviousButtonClick.bind(this)} />) : null}
        {current_step == 3 && current_step<totalSteps+1 ? (<Step4 data={userForm?.employee} details={this.state} onNextButtonClick={onNextButtonClick.bind(this)} onPreviousButtonClick={onPreviousButtonClick.bind(this)} />) : null}
        {current_step == 4 && current_step<totalSteps+1 ? (<Step5 data={userForm?.expenses} details={this.state} onNextButtonClick={onNextButtonClick.bind(this)} onPreviousButtonClick={onPreviousButtonClick.bind(this)} />) : null}
        {current_step == 5 && current_step<totalSteps+1 ? (<><Step6 data={userForm?.notes} details={this.state} onNextButtonClick={onNextButtonClick.bind(this)} onPreviousButtonClick={onPreviousButtonClick.bind(this)} /> </>) : null}
        {current_step > totalSteps ? (<> 
            Financials Application submitted. <br/>
            <button className="btn btn-previous  btn-primary float-left" onClick={() => onPreviousButtonClick()}>Previous</button>
            </>) : null}
        <br />

        </CardBody>
      </Card> 
    </>);
  }

}

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(App));