import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialogs from "components/Dialog/Dialogs.js";
import CardFooter from "components/Card/CardFooter.js"; 
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import {API } from "aws-amplify"; 
import { connect } from 'react-redux'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save'; 
import draggable from "vuedraggable";
import FormBuilder from "views/Forms/FormBuilder.js"
import { useForm } from 'react-hook-form';
import Delete from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Edit from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Box,  
  Divider,
  IconButton,
  List,
  Link,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import {actions}   from "actions"
import { useHistory } from "react-router-dom";
const data = [
  {
    
    name: 'User Verify'
  },
  {
    
    name: 'User Verify1'
  }
];
const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  rountedBtn:{
    borderRadius:"55px",
    margin:"2px"
  }
});

const useStyles = makeStyles(styles);
const mapStatetoProps = state => { 
   if(state?.commonReducers?.idToken){
      return{
        idToken:state.commonReducers.idToken
      }
    }
    return {}
}

const mapDispatchToProps = dispatch => {
  return {
      showToaster: (toasterMsg,toasterType) => { 
        dispatch(actions.showToaster(toasterMsg,toasterType))
      }
  }
}

const createForms = (name,acModules,isPublished,props,history)=>{
  let steppers = [];
  let formData = {
    name: name,
    status: !isPublished ? "Available" : "Published",
    values: {
      stepper: true,
      steppers: steppers,
    },
  };
  acModules.forEach((mactive, index) => {
    let step = {};
    if (mactive.values) {
      step.stepId = mactive.name;
      step.title = mactive.name;
      step.progress = index + 1;
      step.data = { forms: mactive.values };
      steppers.push(step);
      mactive.values.forEach((ma) => {
        if (Object.prototype.hasOwnProperty.call(ma, "show")) {
          ma.visible = ma.show;
        }
      });
    }
  });
  const myInit = {
    headers: {
      "X-COG-KEY": props.idToken,
    },
    body: { ...formData },
  };
  API.post("Nimo-Service", "customerform", myInit)
    .then((response) => {
      history.push("/nimo/dashboard")
    })
    .catch((err) => {});
}


function FormsHome(props) {
  const classes = useStyles();
  const [avModules,setAvModules] = useState([]);
  const [acModules,setAcModules] = useState([]);
  const { register, handleSubmit, errors } = useForm();  
  const [modules,setModules] = useState(false);
  const [viewModule,setViewModules] = useState(false);
  const [name,setName] = useState("");
  const [formsData,setFormsData] = useState([]) 
  const history = useHistory(); 
  const [dispalyAlert, setDisplayAlert] = useState(false)
  const [itemToDelete, setItemToDelete] = useState({})
  const [isDeleteProgress, setDeleteProgress] = useState(false)

  const confirmTodelete = (item) => {
    setItemToDelete(item);
    setDisplayAlert(true);
  }
  const publish = (item, status) => {
    item.status = status;
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: { ...item },
    };
    API.put("Nimo-Service", "customerform", myInit)
      .then((response) => {        
        props.showToaster(status=="Published"?"Published successfully":"Unpublished successfully")
        getForms(props, setFormsData)
      })
      .catch((err) => {
        props.showToaster(status=="Published"?"Error in published"+err:"Error in Unpublished"+err)
       });
  }
  const deleteItem = ()=> {
    setDisplayAlert(false)
    setDeleteProgress(true)
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        SID: itemToDelete.SID,
      },
    };
    API.del("Nimo-Service", "customerform", myInit)
      .then((response) => {
          setItemToDelete({})
          setDeleteProgress(false)
          props.showToaster("Form has been removed successfully","success")
          getForms(props,setFormsData)
      })
      .catch((err) => {
        setDeleteProgress(false)
        props.showToaster("Failed in removed "+err,"error")
      });
  }
  const editItem = (item)=> {
     history.push("/nimo/editCustomerForm/"+item.SID.split("#")[0]+"/"+item.SID.split("#")[1])
  }
  const getForms = (props,setFormsData)=>{
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
    };
    API.get("Nimo-Service", "customerform", myInit).then((response) => {
      setFormsData(response.items); 
    });
  }
  useEffect(()=>{ 
      if(props.idToken){
        const myInit = {
          headers: {
            "X-COG-KEY": props.idToken,
          },
        };
        API.get("Nimo-Service", "module", myInit).then((response) => {
          setAvModules(response.items); 
        });
        getForms(props,setFormsData)  
      }      
  },[props.idToken])
  return (
    <>
    <GridContainer>
    <GridItem xs={12} sm={12} md={6}>
            
          <Card> 
          <CardHeader plain color="warning">
            <h4 className={classes.cardTitleWhite}>Forms</h4>              
            <p className={classes.cardTitleWhite}>With the form builder you can create you own custom forms</p>           
          </CardHeader>
            <CardBody>
              <Box m={2} pt={3}>
                <Button color="primary" variant="contained" onClick={()=>{history.push("/nimo/createForms")}}>Create Forms</Button>
              </Box>
                
                </CardBody>
                </Card>
                </GridItem>
        
              </GridContainer>
    <GridContainer>
    <GridItem xs={12} sm={12} md={8}> 
            <Card> 
           <CardHeader plain color="warning">
            <h4 className={classes.cardTitleBlack}>Current Forms</h4>
          </CardHeader>
            <CardBody> 
              <MaterialTable
                    title=""
                    columns={[
                      { title: 'Name', field: 'name' },
                      {
                        title: 'EndPoint URL', field: 'endPointURL', render: rowData => {
                          return (
                            <div>
                              { !rowData.endPointURL || <div style={{ 'display': 'flex'}}>
                                <span>
                                  <a href={rowData.endPointURL} target="_blank">{rowData.endPointURL.substring(0, 24)}...</a>
                                </span>
                                <span style={{'cursor': 'pointer' }}>
                                  <FileCopyIcon color="primary" onClick={() => { 
                                    props.showToaster("Copied")
                                    navigator.clipboard.writeText(rowData.endPointURL) 
                                  }} />
                                </span>                                
                              </div>
                              }
                            </div>
                          )
                        }
                      },
                      {
                        title: 'Status', field: 'status', render: rowData => {
                          return (
                            <div style={{ 'display': 'flex'}}>

                              {rowData.status}
                              <div>{rowData.status !== 'Published' ?
                                <PublishIcon style={{ 'cursor': 'pointer' }} onClick={() => publish(rowData, 'Published')} />
                                :
                                <GetAppIcon style={{ 'cursor': 'pointer' }} onClick={() => publish(rowData, 'Available')} />
                              }</div>
                            </div>
                          )
                        }
                      },
                      { title: 'Actions', field: 'actions','align':'left',render:rowData=>{
                         return (
                                <div 
                                >
                                  <InfoIcon style={{ 'cursor': 'pointer' }} onClick={(event) => console.log('info')} />
                                  <Edit style={{ 'cursor': 'pointer'}} onClick= {(event) => editItem(rowData)}/> 
                                  <Delete style={{ 'cursor': 'pointer',color:"red" }} onClick={() => confirmTodelete(rowData)} />
                                  {isDeleteProgress && rowData.SID == itemToDelete.SID?<CircularProgress color="secondary" size={24} className={classes.buttonProgress} />:""}
                                </div>)
                      }}
                    ]}
                    data={formsData}   
                  />
                </CardBody>
                </Card>
                </GridItem>
      <GridItem xs={12} sm={12} md={5}>
        <Card>
          <CardHeader plain color="warning">
            <h4 className={classes.cardTitleWhite}>Modules</h4>  
            <p className={classes.cardTitleWhite}>We have create modules to collect sets information from your customers</p>                    
          </CardHeader>
          <CardBody>
              {!viewModule ?<Link  onClick={()=>{setViewModules(true)}}>
                    View Available Modules
              </Link>:""}
              {viewModule?
              avModules.length ? 
              <><List>
                {avModules.map((module, i) => (
                  <draggable
                    className="list-group drop-zone-height"
                    list={avModules}
                    group="people"
                    onChange={(e)=>{
                      console.log(e)
                    }}
                  >
                  <ListItem
                    divider={i < avModules.length - 1}
                    key={i}
                  > 
                     
                    <ListItemText
                      primary={module.name} 
                    /> 
                  </ListItem>
                  </draggable>
                ))}
              </List>
              <Link  onClick={()=>{setViewModules(false)}}>
                    Hide Details
              </Link></>:"No Modules Available":""}
          </CardBody>
          <CardFooter>               
          </CardFooter>
        </Card>
      </GridItem>  
    </GridContainer>  
          <ConfirmModal open={dispalyAlert} msg="Are you sure you want to delete this form?" confirm={deleteItem} cancel={() => { setDisplayAlert(false) }} />         
    </>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(FormsHome)