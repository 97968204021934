import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SendIcon from '@material-ui/icons/Send';
import Check from '@material-ui/icons/Check';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import ScannerIcon from '@material-ui/icons/Scanner';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { connect } from "react-redux";
import { actions } from "actions";
import { Auth, API } from "aws-amplify";
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

const mapStatetoProps = (state) => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType));
    },
  };
};


function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <HorizontalSplitIcon />,
    2: <SendIcon />,
    2: <ScannerIcon />,
    3: <GroupIcon />,
    4: <SendIcon />,
    5: <AddToQueueIcon />,
    6: <ListAltIcon />,
    7: <ListAltIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  connectorStyle: {
    marginLeft: theme.spacing(-1),
  },
  containerStyle: {
    margin: theme.spacing(5),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Kit Created',
    'Sent for Sample Collection',
    'Sample received',
    "Sent to Sequencing facility",
    "Pending for 16S Report",
    "16S Report in QC",
    "Report Available"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}

function CustomizedSteppers(props) {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(-1);
  const [kitIDs, setKitIDs] = React.useState([]);
  const steps = getSteps();
  const [kitID,setKitID] = React.useState("")

const findHistory = (status,index)=>{
    let statusHistory = kitIDs.statusHistory;
    if(!statusHistory)return <div>{steps[index]}</div>    
    return statusHistory[index+1]?<div>
          <div>{statusHistory[index+1].status}</div>
          <div>{statusHistory[index+1].user}</div>
           <div>{statusHistory[index+1].date}</div></div>
    :<div>{steps[index]}</div>;
}
const getKitIDStatus = () => { 
    if(!kitID){
      props.showToaster("Please enter Kit ID","error")
    }
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        kitID: kitID,
      },
    };
    API.get("Amili-Service", "", myInit)
      .then((response) => { 
        if(response.items && response.items.length){
           setKitIDs(response.items[0])
           let status = response.items[0].statusHistory
           let keys = Object.keys(status) 
           let currentStatus = Math.max(...keys)         
           setActiveStep(currentStatus.toString())
        }
      })
      .catch((err) => {
        props.showToaster("Failed in get kitIDs "+err,"error")
      });
  }; 
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(-1);
    setKitID("")
    setKitIDs([])
  };

  return (
    <div className={classes.root}> 
    <GridContainer spacing={3} justify="center">
        <GridItem xs={12} sm={12} md={10}>
        
       <CustomInput
                labelText="Enter Kit ID"
                id="cname"
                formControlProps={{
                  fullWidth: true
                }} 
                inputProps={{ 
                  value:kitID,
                  onChange: (e) => {
                    setKitID(e.target.value)
                  }
                }}
              />
        </GridItem>
<Button color="primary" onClick={getKitIDStatus} className={classes.button}>Search</Button>
<Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> 
            </GridContainer>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel className={classes.connectorStyle} StepIconComponent={ColorlibStepIcon}>
                {
                    findHistory(label,index)
                }
            </StepLabel>
             
          </Step>
        ))}
      </Stepper>      
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(CustomizedSteppers);