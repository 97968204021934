import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function NewUser(props) {

  const [password, setPassword] = React.useState("")
  const [retype_password, resetPassword] = React.useState("")
  const submitpassword = () => {
    if (password === retype_password) props.newUserSignUp(password);
    else alert('Please check again')
  };
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Set up your Account </DialogTitle>

        <DialogContent>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            name="password"
            label="New Password"
            type="password"
            onChange={e => setPassword(e.target.value)}
            id="password"
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={retype_password}
            name="Retype password"
            label="Password"
            type="password"
            onChange={e => resetPassword(e.target.value)}
            id="password"
            autoComplete="current-password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={submitpassword} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
