import React,{useEffect} from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
//import Toolbar from '@material-ui/core/Toolbar';
import Navbar from "components/Navbars/Navbar.js";
import Snackbar from "components/Snackbar/Snackbar.js";
//import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { Auth,API } from "aws-amplify"; 
import {routes,barRoutes,uploadLabRoutes,uploadQualtricsRoutes,qcRoutes}  from "routes.js";
import { connect } from 'react-redux'
import {actions}   from "actions"
import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";

let ps; 
let routesContents = [...routes,...barRoutes,...uploadLabRoutes,...uploadQualtricsRoutes,...qcRoutes]
const switchRoutes = (
  <Switch>
    {routesContents.map((prop, key) => {
      if (prop.layout === "/amili") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })} 
  </Switch>
);

const useStyles = makeStyles(styles);
const mapDispatchToProps = dispatch => {
  return {
    setSession: (username,session,group) => { 
        dispatch(actions.setSession(username,session,group))
    }
  }
}
const mapStatetoProps = state => {   
  if (state?.commonReducers?.showToaster) {
    return {
      showToaster: state.commonReducers.showToaster,
      toasterMsg: state.commonReducers.toasterMsg,
      toasterType: state.commonReducers.toasterType
    }
  }
  return {}
}

function Admin({ ...rest }) {
  
  const classes = useStyles(); 
  const mainPanel = React.createRef(); 
  
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const history = useHistory(); 
  const handleDrawerOpenClose = ()=>{
    setOpen(!open)
  }
  const handleImageClick = image => {
    setImage(image);
  };
  const handleColorClick = color => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/nimo/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
   useEffect( ()=>{    
     const getSession = ()=>{
        Auth.currentSession().then((session)=>{
           const {idToken} = session
           const group = session.accessToken.payload["cognito:groups"] 
           rest.setSession(session.accessToken.payload.username,idToken,group.toString())   
        }).catch(()=>{
           history.push("/page/login")
        })
        //const session  = await Auth.currentSession();
        //const {idToken} = session
        //rest.setSession(session.accessToken.payload.username,idToken)   
     }
     getSession()
     
  },[rest])
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
    <Snackbar openSnack={rest.showToaster} message={rest.toasterMsg} type={rest.toasterType} timeout={3000}/>
    <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          handleDrawerOpenClose={handleDrawerOpenClose}
          {...rest}
        /> 
        <div></div>
     {open ? <Sidebar
        routes={routes}
        barRoutes = {barRoutes}
        uploadQualtricsRoutes = {uploadQualtricsRoutes}
        uploadLabRoutes = {uploadLabRoutes}
        qcRoutes = {qcRoutes}
        logoText={"Nimo"}
        logo={logo} 
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />:""}
      <div className={open?classes.mainPanel:classes.mainPanelFullWidth} ref={mainPanel}>        
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}  
      </div>

    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(Admin)