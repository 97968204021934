import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardIcon from "components/Card/CardIcon.js";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js"; 
import MaterialTable, { MTableToolbar } from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from '@material-ui/core/Switch';
import Icon from "@material-ui/core/Icon";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Print from "@material-ui/icons/Print";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ScannerIcon from '@material-ui/icons/Scanner';
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import CustomInput from "components/CustomInput/CustomInput.js";
import Fab from "@material-ui/core/Fab";
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import { actions } from "actions";
import Dialogs from "components/Dialog/Dialogs.js"
import { Auth, API } from "aws-amplify";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(50),
      height: theme.spacing(25),
    },
  },
  btnActions:{
    float: "right",
    bottom: 0,
    position: "relative",
    top: "-9%"
  },
  switchAction:{
      bottom: 0,
      margin: "10px",
      position: "relative",
      top: "16%"
  },
  cameraWidth:{
    [theme.breakpoints.up("xs")]: {
      width: 400
    },[theme.breakpoints.down("md")]: {
      width: 250
    },
  },
  scanSuccess:{
    background: "green",
    color: "white",
    padding: "10px", 
    marginBottom: "10px"
  },
  scanFailed:{
    background: "red",
    color: "white",
    padding: "10px", 
    marginBottom: "10px"
  },
  theme,
}));

const mapStatetoProps = (state) => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      isLoader: state.commonReducers.isLoader,
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType));
    },
    showLoader: (msg) => {
      dispatch(actions.showLoader(msg));
    },
    hideLoader: () => {
      dispatch(actions.hideLoader());
    }
  };
};

function ScanList(props) {
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [kitIDs, setKitIDs] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [kitID,setKitID] = useState("")
  const [continueScan, setContinue] = useState(false)
  const [showScanDialog, setScanDialog] = useState(false);
  const [sendSequencing, setSendSequencing] = useState(false); 
  const [scanStatus,setScanStatus] = useState("")  
  useEffect(() => {
    if (!props.idToken) return;
    props.showLoader()
    getKitIDs(props);
  }, [props.idToken]);
  
  const getSelectedKitIds = (items)=>{
      items = items.filter((item)=>{
        return item.kitStatus !=1;
      })
      return items
  }
  const updateCollectionStatus = (e,rowData)=>{ 
      //setSendCollectionKit(e.target.checked)
      updateItem(rowData,4)
  }
  const getKitIDs = () => {
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        ID: "KITID",
      },
    };
    API.get("Amili-Service", "", myInit)
      .then((response) => { 
        if(response.items && response.items.length){          
          setKitIDs(getSelectedKitIds(response.items))
        }
        props.hideLoader()
      })
      .catch((err) => {
        props.showToaster("Failed in get kitIDs "+err,"error")
      });
   };
   
   const MnaualscanKitID = ()=>{ 
     let item = null;      
     if(!kitID) return
      item = kitIDs.find((kit)=>{
        return kit.kitID.indexOf(kitID)!=-1 && kit.kitStatus==2
      })
      if(item){
        updateItem(item,3)
      }else{
        props.showToaster("Invalid kit ID or Kit ID may be moved to next stage","error")
      }
   }
    const scanKitID = (scanID)=>{
      setScanStatus(0)
     let item = null; 
     if(!scanID) return
      item = kitIDs.find((kit)=>{
        return kit.kitID.indexOf(scanID)!=-1 && kit.kitStatus==2
      })
      if(item){
        //setKitID(scanID)
        updateItem(item,3)
      }else{
        setScanStatus(1)
        setTimeout(()=>{
            setScanStatus(0)
        },5000) 
      }
   }
   const updateItem = (item, kittSatus) => {
      item.kitStatus = kittSatus
      const myInit = {
        headers: {
          "X-COG-KEY": props.idToken,
        },
        body: { ...item },
      };        
      API.put("Amili-Service", "", myInit)
        .then((response) => {
          if(kitID){
            props.showToaster("Status updated successfully")
          }else{
            setScanStatus(2)
            setTimeout(()=>{
                setScanStatus(0)
            },5000)
            setContinue(true)  
          }          
        if(response.items){          
          setKitIDs(getSelectedKitIds(response.items))
        }
      })
      .catch((err) => {
        props.showToaster("Error in update kitID"+err)
      });
    }
  const classes = useStyles(); 
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm')); 
  const calcTimeZone = (time,offset)=>{ 
    let d = new Date(time); 
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000); 
    let nd = new Date(utc + (3600000*offset)); 
    return nd.toLocaleString(); 
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        
        <Card>
            <CardHeader plain color="warning">
              <Box display="flex">
                <Box flexGrow={1}>
                  <h4 className={classes.cardTitleWhite}>Scan Kit IDs</h4>
                </Box>
                <Box>
                  <Button color="warning" onClick={()=>{setShowDialog(true)}}>Manual Scan</Button>
                  <Fab size="small" color="primary" aria-label="add">
                    <ScannerIcon onClick={()=>{setScanStatus(0);setScanDialog(true)}}/>
                  </Fab>
                  
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
                {props.isLoader? <Skeleton animation="wave" variant="rect"  height={300} />: <MaterialTable
                title=""
                columns={[
                  {defaultSort:"desc", title: 'Kit ID',cellStyle: {
       width: '20%'
     }, field: 'kitID', render: rowData => {
                      return (
                        <div>  
                          <div>{rowData.status !== 'Published' ?
                             <Barcode fontSize={15} lineColor={"#990000"} height={20} width={1}  marginRight={4} value={rowData.kitID} />
                            :
                             ""
                          }</div>
                        </div>
                      )
                    } }, 
                  {
                    title: 'Status', field: 'status'
                  },
                   
                  {
                    title: 'Scanned on', field: 'statusHistory', render: rowData => {
                      return (
                        <div>
                           {rowData.statusHistory[3]?.date?rowData.statusHistory[3]?.date:""}
                        </div>)
                    }
                  },
                  {
                    title: 'Sequence ID', field: 'sequenceID', render:rowData => {
                      return (
                        <div>  
                          {rowData.sequenceID?rowData.sequenceID:""}

                          </div> 
                      )
                    }
                  },
                  {
                    title: 'Sent to Sequencing facility', field: 'sequencefacility', render: rowData => {
                      return (
                        <div> 
                          {rowData.sequenceID?<Switch
                              onChange={(e)=>{if(rowData.kitStatus==3)updateCollectionStatus(e,rowData)}}
                              checked={rowData.kitStatus==4?true:false}
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            /> :""}
                           
                        </div>
                      )
                    }
                  }
                ]}
                data={kitIDs}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                  sorting: true,
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                  })
                }}
              />}
             
            </CardBody>

          </Card>

          
        </GridItem>
      </GridContainer>
      <Dialogs width={"500"} open={showDialog} title="Kit ID" isEdit={true} content={
         <CustomInput
                labelText="Enter Kit ID" 
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{   
                  onChange: (e) => {
                    setKitID(e.target.value)
                  }
                }}
              />
      } handleClose={()=>{setShowDialog(false)}} handleSave={()=>{MnaualscanKitID();setShowDialog(false)}}/>
      <Dialogs  open={showScanDialog} title="Scan Bar Code"   content={
        <div>
          <div>{scanStatus==2?<div className={classes.scanSuccess}>Scanned & Status update successfully</div> :(scanStatus==1?<div className={classes.scanFailed}>Invalid kit ID or Kit ID may be moved to next stage</div> :"")}</div>
         {!continueScan ?<BarcodeScannerComponent
            width={matches?450:250}
            height={matches?300:"auto"}
            onUpdate={(err, result) => { 
              if (result) {
                setKitID(result.text)
                scanKitID(result.text)
              }
            }}
          />:"Please click Continue to scan"}
          </div>
      } handleClose={()=>{setScanDialog(false)}} handleSave={()=>{setContinue(false)}} primaryLabel={"Continue"} isEdit={continueScan?true:false}/> 
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(ScanList);
