import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl'; 
import FormLabel from "@material-ui/core/FormLabel";
import classNames from "classnames";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) { 

  return (
    <Radio
      className={props.classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
      icon={<span className={props.classes.icon} />}
      {...props}
    />
  );
}

export default function CustomizedRadios(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,     
    inputProps,
    error,
    name,
    success
  } = props;  
  return (
    <FormControl
       name={name}
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
    >
      {labelText !== undefined ? (
         <FormLabel component="legend">{props.labelText} {inputProps.required?<span className="text-danger"> * </span>:""} </FormLabel>
      ) : null}
      <RadioGroup {...inputProps} row aria-label={inputProps.value} name="customized-radios">
      {
          inputProps.options && inputProps.options.map((option,index)=>(

              <FormControlLabel value={option.value} labelPlacement="start" control={<StyledRadio classes={classes}/>} label={option.label} />
          ))
      }
      </RadioGroup>  
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>    
  );
}
