import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardIcon from "components/Card/CardIcon.js";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js"; 
import MaterialTable, { MTableToolbar } from "material-table";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from '@material-ui/core/Switch';
import Icon from "@material-ui/core/Icon";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Print from "@material-ui/icons/Print";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import { actions } from "actions";
import { Storage ,Amplify} from "aws-amplify";
import { Auth, API } from "aws-amplify";
import config from 'config';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(50),
      height: theme.spacing(25),
    },
  },
  btnActions:{
    float: "right",
    bottom: 0,
    position: "relative",
    top: "-9%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  switchAction:{
      bottom: 0,
      margin: "10px",
      position: "relative",
      top: "16%"
  },
  spacing10:{
    margin: theme.spacing(1)
  }
}));

const mapStatetoProps = (state) => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      isLoader: state.commonReducers.isLoader
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType));
    },
    showLoader: (msg) => {
      dispatch(actions.showLoader(msg));
    },
    hideLoader: () => {
      dispatch(actions.hideLoader());
    }
  };
};
 const getSelectedKitIds = (items)=>{
      items = items.filter((item)=>{
        return item.kitStatus >= 6;
      })
      return items
}

function ReportComp(props) {
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null); 
  const [kitIDs, setKitIDs] = useState([]);
  const [itemToApprove, setItemToApprove] = useState({})
  const [dispalyAlert, setDisplayAlert] = useState(false)
  const [isApproveProgress, setApproveProgress] = useState(false)
  const [reportStatus, setReportStatus] = useState(false)
  const [backdropProgress, setBackdropProgress] = useState(false)
  useEffect(() => {
    if (!props.idToken) return;
    props.showLoader()
    getKitIDs();
  }, [props.idToken]);
  const classes = useStyles();
  const confirmToApprove = (item,strStatus,status) => {
    setItemToApprove(item);
    setDisplayAlert(true);
    setReportStatus(status)
  }
  const getKitIDs = () => {
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        ID: "KITID",
      },
    };
    API.get("Amili-Service", "", myInit)
      .then((response) => { 
        if(response.items && response.items.length){          
          setKitIDs(getSelectedKitIds(response.items))
        }
        props.hideLoader()
      })
      .catch((err) => {
        props.showToaster("Failed in get kitIDs  "+err,"error")
      });
   };
   const approveDoc = ()=> {      
    setDisplayAlert(false)
    setApproveProgress(true)
    itemToApprove.kitStatus = reportStatus 
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: { ...itemToApprove },
    };
    API.put("Amili-Service", "", myInit)
      .then((response) => {
          setItemToApprove({})
          setApproveProgress(false)
          if(reportStatus == 7){    
            props.showToaster("Report has been approved successfully","success");        
            convertToPDF()
          }else{
            props.showToaster("Report has been unapproved successfully","success")
          }          
          if(response.items){          
            setKitIDs(getSelectedKitIds(response.items))
          }
      })
      .catch((err) => {
        setApproveProgress(false)
        props.showToaster("Failed in update kitStatus"+err,"error")
      });
  }
  const convertToPDF = async(kitIDurl)=> {   
    setBackdropProgress(true)  
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        fileName:itemToApprove.kitIDurl.substr(itemToApprove.kitIDurl.lastIndexOf("/")+1)
      }
    };
    API.get("Amili-Service", "/common", myInit)
      .then((response) => {  
        setBackdropProgress(false);
        props.showToaster("Report converted to pdf","success");
      })
      .catch((err) => { 
        setBackdropProgress(false);
        props.showToaster("Report converted to pdf","success");
        //props.showToaster("Failed in convert to PDF"+err,"error")
      });
  }
  const downloadFile = async (kitIDurl)=>{
    let bucket = kitIDurl.substr(0,kitIDurl.lastIndexOf("/"))
    let key = kitIDurl.substr(kitIDurl.lastIndexOf("/")+1)     
    Amplify.configure({ 
      Storage:{
        AWSS3: {
          bucket:config.S3.reports_bucket,
          region: config.S3.region 
        },
        customPrefix: {
            public: config.S3.reports_folder
        }    
      }
    }); 
    const data = await Storage.get(key, { download: true,level: 'public' })     
    const url = URL.createObjectURL(data.Body);
    const a = document.createElement('a');
    a.href = url;
    a.download = key || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        
        <Card>
            <CardHeader plain color="warning">
              <Box display="flex">
                <Box flexGrow={1}>
                  <h4 className={classes.cardTitleWhite}>QC Reports</h4>
                </Box> 
              </Box>
            </CardHeader>
            <CardBody>
                {props.isLoader ? <Skeleton animation="wave" variant="rect" width={"auto"} height={300} />: <MaterialTable
                title=""
                columns={[                    
                  {
                    title: 'Report File', field: 'kitIDurl', render: rowData => {
                      return (
                        <div>
                           <a href="#" onClick={(e)=>{ 
                              e.preventDefault();
                              downloadFile(rowData.kitIDurl);
                            }}>{rowData.kitIDurl? rowData.kitIDurl.substr(rowData.kitIDurl.lastIndexOf("/")+1):""}</a>
                           
                        </div>)
                    }
                  },
                  {
                    title: 'Status', field: 'status'
                  },
                  {
                    title: 'Actions', field: 'actions', 'align': 'left', render: rowData => {
                      return (
                        <div>
                          { rowData.kitStatus!=7 ?<span>
                          <ThumbUpIcon style={{ 'cursor': 'pointer',"margin":"10px" ,color: "green"}}  onClick={(event) =>confirmToApprove(rowData,'approved',7)}  /> 
                          <ThumbDownIcon style={{ 'cursor': 'pointer', color: "red" }} onClick={(event) =>confirmToApprove(rowData,'unapproved',6)} />
                          {isApproveProgress && rowData.SORTID == itemToApprove.SORTID?<CircularProgress color="secondary" size={24} className={classes.buttonProgress} />:""}</span>:""
                          }
                        </div>)
                    }
                  }
                ]}
                data={kitIDs}
                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                  })
                }}
              />}
             
            </CardBody>

          </Card>

          
        </GridItem>
      </GridContainer>
      <Backdrop className={classes.backdrop} open={backdropProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmModal open={dispalyAlert} msg="Are you sure you want approve?" confirm={approveDoc} cancel={() => { setDisplayAlert(false) }} />         
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(ReportComp);
