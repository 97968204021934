import React, { useEffect, useState } from "react";
// @material-ui/core
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardIcon from "components/Card/CardIcon.js";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js"; 
import MaterialTable, { MTableToolbar } from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from '@material-ui/core/Switch';
import Icon from "@material-ui/core/Icon";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Print from "@material-ui/icons/Print";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ScannerIcon from '@material-ui/icons/Scanner';
import InfoIcon from "@material-ui/icons/Info";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ListAltIcon from '@material-ui/icons/ListAlt';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem"; 
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import Fab from "@material-ui/core/Fab";
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import { actions } from "actions";
import Dialogs from "components/Dialog/Dialogs.js"
import { Auth, API } from "aws-amplify";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import Dropzone from "react-dropzone";
const theme = createMuiTheme({
            palette: {
              primary: { 500: "#d10d0d" }
            },
          });
const useStyles = makeStyles((theme) => ({
  dropzoneStyle:{
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
    padding:"20px"
  },
  "attributes":{
    "display": "grid",
    "gridTemplateColumns": "repeat(auto-fit, minmax(10ch, 1fr))",  
    "gridGap": "1em",
    margin: 0,
    opacity: 0.75,
    transition: "0.25s ease-in-out all"
  },
  "key":{
    "gridColumnStart":"1",
    "fontFamily":"Passion One",
    "fontSize": "1.1em",
    "lineHeight": "1rem",
    "letterSpacing": "0.05em",
    "textTransform": "uppercase",
    "color":"#9340bf"
  },
  "value":{
    margin: 0,
    "gridColumnStart":"span 1",
    "wordBreak":"break-all"
  },
   overflow:{
     fontWeight:"350",
     width:"200px",
     display:"block",
     whiteSpace:"nowrap",
     overflow:"hidden",
     textOverflow:"ellipsis"
   },  
   badge: {
    top: 1,
    right: -10,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
  card: {
    maxWidth: 300, 
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      cursor:"pointer"
    }
  },
  icon: {
    transition: "0.3s",
    "&:hover": { 
      "marginTop":"2px",
      "paddingTop":"2px",
      cursor:"pointer",
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    }
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(50),
      height: theme.spacing(25),
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  btnActions:{
    float: "right",
    bottom: 0,
    position: "relative",
    top: "-9%"
  },
  switchAction:{
      bottom: 0,
      margin: "10px",
      position: "relative",
      top: "16%"
  },
  cameraWidth:{
    [theme.breakpoints.up("xs")]: {
      width: 400
    },[theme.breakpoints.down("md")]: {
      width: 250
    },
  },
  scanSuccess:{
    background: "green",
    color: "white",
    padding: "10px", 
    marginBottom: "10px"
  },
  scanFailed:{
    background: "red",
    color: "white",
    padding: "10px", 
    marginBottom: "10px"
  },
  theme,
}));

const mapStatetoProps = (state) => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      isLoader: state.commonReducers.isLoader,
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType));
    },
    showLoader: (msg) => {
      dispatch(actions.showLoader(msg));
    },
    hideLoader: () => {
      dispatch(actions.hideLoader());
    }
  };
};

function SurveyMapping(props) {
  const history = useHistory();
  const [surveyItem, setSurveyItem] = useState({});
  const [surveyMainDatas, setMainSurveys] = useState([]); 
  const [excludeItems, setExcludeItems] = useState([]); 
  const [surveyTypes, setSurveyTypes] = useState([]); 
  const [surveyObject, setSurveyObject] = useState({}); 
  const [surveyTypesAlone, setSurveyTypesAlones] = useState([]); 
  const [isEdit,setEdit] = useState(false) 
  const [muiTheme, setTheme] = React.useState({});
  const [showDetailPane, setDetailPane] = useState(false); 
  const [suveryTitle, setSurveryTitle] = useState("");
  const [surveyVersionDetails, setSurveryVersionDetails] = useState([]);
  const [surveyTypeId, setSurveyTypeID] = useState(""); 
  const [progress, setProgress] = React.useState(0);
  const [isDeleteProgress, setDeleteProgress] = useState(false)
  const [itemToDelete, setItemToDelete] = useState({})
  const [selectedSuveyItem, setSelectedSurveyItem] = React.useState({});
  const [selectedParams, setSelectedParams] = useState([]);
  const [encrptParams, setEncrptParams] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false); 
  const [showEditDialog, setShowEditDialog] = useState(false); 
  const [dispalyAlert, setDisplayAlert] = useState(false)
  const [dispalyVersionAlert, setDisplayVersionAlert] = useState(false)
  const isAllSelected =
    encrptParams.length > 0 && selectedParams.length === encrptParams.length;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [showInfoDialog, setShowInfoDialog] = useState(false); 


  const csvColumns = [ 
            { header: 'Category', dataKey: 'category' },
            { header: 'Sub Category', dataKey: 'sub_category' },           
            { header: 'Parameter Name', dataKey: 'param_nm' },          
            { header: 'Survey Param', dataKey: 'survey_param' },
            { header: 'Param Type', dataKey: 'param_type' },
            { header: 'Active', dataKey: 'is_active' }
        ]

  let acceptFileType = ".csv"
  useEffect(() => {
    if (!props.idToken) return;
    props.showLoader()
    getMainSurveyTypes()
    getEncrptParams(props)    
    setTheme(theme)
  }, [props.idToken]); 
  useEffect(() => {
    if (!props.idToken) return;
    props.showLoader() 
    getSurveyTypeMapping(); 
  }, [surveyMainDatas]); 
  const uploadData = (data)=>{

  }
  const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250
        }
      },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      variant: "menu"
    };

  const showMessage = (response)=>{
      let msgType = "success"
      if(response && response.error){
          msgType = "error"          
      }
      props.showToaster(response.message,msgType)
  }
  const downloadData = (rowData)=>{
      getSurveyTypeMapping(rowData.ver_id)      
  }
  const updateSurveyType = ()=>{
      let data = {...surveyItem,...{"encrypt_params":selectedParams.toString()}}
      updatesurveyAPI(data)
  }
  const changeVersionStatus = (e,rowData)=>{
    let status = e.target.checked
    setSelectedSurveyItem(rowData)
    updateVersionStatus({ ver_id: rowData.ver_id,isactive:status?'Y':"N",sur_typ_id:rowData.sur_typ_id })  
    //getSurveyTypeMapping(null,true)
  }
  const addSurvey = ()=>{
    setSelectedParams([]);
    setEdit(false);
    setShowAddDialog(true);
    setSurveyItem({})
  }
  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedParams(selectedParams.length === encrptParams.length ? [] : encrptParams);
      return;
    }
    setSelectedParams(value);
  };  

  const fileDrop = async (files, rowData)=> {  
    const convertToFile = (file) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onloadend = function () {
                resolve(fileReader.result);
            };
            fileReader.readAsText(file);
        });
    }
    const convertToJson = (content)=>{
      const result = [];
      const headers = content[0].split(",");
      for (let i = 1; i < content.length; i++) {
          const obj = {};
          const currentline = content[i].split(",");
          for (let j = 0; j < headers.length; j++) {
            let header = headers[j].replace(/"/g, '');
            for (var k = 0; k < csvColumns.length; k++) {
                if(csvColumns[k].header == header){
                  header = csvColumns[k].dataKey
                  break;
                }
            }            
            if(currentline[j] && typeof currentline[j] == 'string'){                
                obj[header] = currentline[j].replace(/"/g, ''); 
                obj.ver_id = rowData.ver_id 
                obj.common_param = (obj.category?obj.category:"")+""+(obj.sub_category?"__"+obj.sub_category:"")+""+(obj.param_nm?"__"+obj.param_nm:"")                
            }              
          }
          if(Object.keys(obj).length>0)
            result.push(obj);
      }
      console.log(result)
      createBulkSurveyMapping(result)
      return result
    }
    var base64 = await convertToFile(files[0]);
    var content = base64.split("\r\n");
    //console.log(content)
    convertToJson(content)
  }
  const showVersions = (selectedItem)=>{
    setDetailPane(true)
    setSelectedSurveyItem(selectedItem)
    setSurveryTitle(selectedItem.survey_type+"("+(selectedItem.ver_id?selectedItem.ver_id:"No Version")+")")
    let items = surveyObject[selectedItem.sur_typ_id]
    if(items && items.length){
      setSurveryVersionDetails(items)
    }else{
      setSurveryVersionDetails([])
    }       
  }
  const exportToCsv = (mappingData,ShowLabel) =>{ 
        var arrData = typeof mappingData != 'object' ? JSON.parse(mappingData) : mappingData;
        var CSV = ''; 
        
        if(ShowLabel){
          for (var i = 0; i < csvColumns.length; i++) {
             CSV += '"' + csvColumns[i].header + '",';
          }
          CSV +='\r\n';
        }        
        for (var k = 0; k< arrData.length;k++) {
            for (var j = 0; j < csvColumns.length; j++) {
               var value = arrData[k][csvColumns[j].dataKey]?arrData[k][csvColumns[j].dataKey]:""
               CSV += '"' + value + '",'
            }
            CSV +='\r\n';
        }        
        if (CSV == '') {        
            alert("Invalid data");
            return;
        }   
        var link = document.createElement("a");    
        link.id="lnkDwnldLnk";
        document.body.appendChild(link);
        var csv = CSV;  
        var blob = new Blob([csv], { type: 'text/csv' }); 
        var csvUrl = window.webkitURL.createObjectURL(blob);
        var filename = 'Survey_Mapping.csv';
        link.setAttribute("href", csvUrl);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';   
        link.click();
        document.body.removeChild(link);
    }
  const updatesurveyAPI = (data)=>{
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body:{...data}
    };
    API.put("Amili-Survey-Service", "survey-type", myInit)
      .then((response) => {
          showMessage(response)
          if(response.body){          
            setSurveyTypes(response.body);
            getMainSurveyTypes()
            getSurveyTypeMapping()
          }
      })
      .catch((err) => { 
        props.showToaster("Failed in deactive surveytype"+err,"error")
      });
  }
  const updateVersionStatus = (data)=>{
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body:{...data}
    };
    API.put("Amili-Survey-Service", "survey-version", myInit)
      .then((response) => {
          showMessage(response)
          getSurveyTypeMapping(null,true)
      })
      .catch((err) => { 
        props.showToaster("Failed in deactive surveytype"+err,"error")
      });
  }
  const createBulkSurveyMapping = (data)=> { 
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: data,
    };
    API.post("Amili-Survey-Service", "survey-mapping", myInit)
      .then((response) => {
          showMessage(response)
          if(response.body){          
            //setSurveyTypes(response.body);

            groupSurveyDatas(response.body,true)
            
          }
      })
      .catch((err) => { 
        props.showToaster("Failed in create surveytype mapping"+err,"error")
      });
  }
  const getSurveyTypeMapping = (ver_id,isRefresh) => { 
    var data = {}
    if(ver_id){
       data = {ver_id:ver_id}
    }
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      }, 
      queryStringParameters: {...data}
    };
    API.get("Amili-Survey-Service", "survey-mapping", myInit)
      .then((response) => {     
        if(response.body && response.body.length){ 
          if(ver_id){
            exportToCsv(splitCatgory(response.body),true)
          }else{            
            groupSurveyDatas(response.body,isRefresh)
          }
          
        }
        props.hideLoader()
      })
      .catch((err) => {
        console.log('within surveytype.js api response catch block');
        props.showToaster("Failed in get survey version list "+err,"error")
      });
   }; 
   const getMainSurveyTypes = () => { 
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      }
    };
    API.get("Amili-Survey-Service", "survey-type", myInit)
      .then((response) => {     
        if(response.body && response.body.length){ 
          setMainSurveys(response.body);
        }
        props.hideLoader()
      })
      .catch((err) => { 
        props.showToaster("Failed in get survey type list "+err,"error")
      });
   };

   const splitCatgory = (items)=>{
      if(items && items.length>0){
        items.forEach((item)=>{
           let categorys = item.common_param.split("__")
           if(categorys && categorys.length>0){
             item.category = categorys[0]?categorys[0]:""
             item.sub_category = categorys[1]?categorys[1]:""
             item.param_nm = categorys[2]?categorys[2]:""
           }
        })
      }
      return items
   }
  const groupSurveyDatas = (res,isRefresh)=>{
    let result = []
    let keys = []
    let surveyObj = {}
    let count = 0;
    if(res && res.length){
      for(let i=0;i<res.length;i++){
        if(keys.indexOf(res[i].sur_typ_id)==-1){
          keys.push(res[i].sur_typ_id)
          res[i].parentId = res[i].id
          delete res[i].id
          surveyObj[res[i].sur_typ_id] = []
          surveyObj[res[i].sur_typ_id].push(res[i])
        }else{
          if(surveyObj[res[i].sur_typ_id]){
            res[i].parentId = res[i].id
            surveyObj[res[i].sur_typ_id].push(res[i])
          }
        }
      }
      //console.log(surveyObj)

      let surveyKeys = Object.keys(surveyObj)
      let object = {}
      let surveys = []
      for(let j=0;j<surveyKeys.length;j++){
        if(surveyObj[surveyKeys[j]] && surveyObj[surveyKeys[j]].length){
          object = {...surveyObj[surveyKeys[j]][0],...{id:surveyObj[surveyKeys[j]][0].sur_typ_id}} 
          delete object.parentId
          delete object.is_active
          delete object.isactive
          surveys.push(object)  
        }        
        result = result.concat(surveyObj[surveyKeys[j]])
      }
      //concat survey types and survey mapping results


      console.log("setSurveyTypes",result)
      console.log("SurveyTypesAlones",surveys)
      console.log("SurveyTypesMains",surveyMainDatas)
      excludeSurveyItems(surveyMainDatas,surveys)
      setSurveyObject(surveyObj)
      setSurveyTypes(result);  
      setSurveyTypesAlones(surveys)
      if(isRefresh){
        let items = surveyObj[selectedSuveyItem.sur_typ_id]
        if(items && items.length){
          setSurveryVersionDetails(items)
        }
      }
      
     
    }
  }
  const editItem = (rowData)=>{
    setSelectedParams(rowData.encrypt_params?rowData.encrypt_params.split(","):[])
    setShowAddDialog(true)
    setSurveyItem(rowData)
    setEdit(true)
  }
  const viewItem = (rowData)=>{
     setShowInfoDialog(true)
     setSurveyItem(rowData)
  }
  const excludeSurveyItems = (arr1,arr2)=>{
    const isSameData = (a, b) => a.sur_typ_id == b.sur_typ_id;
    const onlyInLeft = (left, right, compareFunction) => 
      left.filter(leftValue =>
        !right.some(rightValue => 
          compareFunction(leftValue, rightValue)));
    const onlyInA = onlyInLeft(arr1, arr2, isSameData);
    const onlyInB = onlyInLeft(arr2, arr1, isSameData);

    const result = [...onlyInA, ...onlyInB];
    setExcludeItems(result)
    console.log("excludeItems",result)
    return result
  }
  const findActiveVersion = (id)=>{
      let av = null; 
      let items = surveyObject[id]
      //console.log(items)
      if(!items) return av;
      for(let i=0;i<items.length;i++){
        if(items[i].survey_type_ver_id && items[i].isactive=='Y'){
          av = items[i].survey_type_ver_id
        }
      }
      return av
  }
  const getVersions = (id)=>{
    let items = surveyObject[id]
    return items && items.length?items.length:"0"
  }
  const createSuveryVersion = (surveyTypeID)=> {
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: {
        ...surveyVersionDetails,...{sur_typ_id:surveyTypeID}
      },
    };
    API.post("Amili-Survey-Service", "survey-version", myInit)
      .then((response) => {
          showMessage(response)
          if(response.body){ 
            getSurveyTypeMapping();
          }
      })
      .catch((err) => { 
        props.showToaster("Failed in create surveytype"+err,"error")
      });
  }
  const deleteItem = ()=> {
    setDisplayAlert(false)
    //setDeleteProgress(true)
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        sur_typ_id: itemToDelete.sur_typ_id,
      },
    };
    API.del("Amili-Survey-Service", "survey-type", myInit)
      .then((response) => {
          setItemToDelete({})
          //setDeleteProgress(false)
          showMessage(response)
          if(response.body){          
            //setSurveyTypes(response.body);
            getMainSurveyTypes()
            getSurveyTypeMapping(null,true)
          }
      })
      .catch((err) => {
        //setDeleteProgress(false)
        props.showToaster("Failed in deactive surveytype"+err,"error")
      });
  }
  const deleteVersionItem = ()=> {
    setDisplayVersionAlert(false)
    //setDeleteProgress(true)
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        ver_id: itemToDelete.ver_id,
      },
    };
    API.del("Amili-Survey-Service", "survey-version", myInit)
      .then((response) => {
          setItemToDelete({})
          showMessage(response)
          if(response.body){          
            //setSurveyTypes(response.body);
            getMainSurveyTypes()
            getSurveyTypeMapping(null,true)
          }
      })
      .catch((err) => {
        //setDeleteProgress(false)
        props.showToaster("Failed in deactive surveytype"+err,"error")
      });
  }
  const saveSurveyType = ()=> {
    surveyItem["encrypt_params"] = selectedParams.toString()
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      body: {
        ...surveyItem
      },
    };
    API.post("Amili-Survey-Service", "survey-type", myInit)
      .then((response) => {
          showMessage(response)
          if(response.body){          
            setSurveyTypes(response.body);
            getMainSurveyTypes()
            getSurveyTypeMapping()
          }
      })
      .catch((err) => { 
        props.showToaster("Failed in create surveytype"+err,"error")
      });
  }
  const getEncrptParams = () => { 
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      }, 
    };
    API.get("Amili-Survey-Service", "survey-encrpt-params", myInit)
      .then((response) => {     
        if(response && response.length){ 
          setEncrptParams(response);
        } 
      })
      .catch((err) => { 
        props.showToaster("Failed in getEncrptParams list "+err,"error")
      });
   }; 
  const classes = useStyles(); 
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm')); 
  const calcTimeZone = (time,offset)=>{ 
    let d = new Date(time); 
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000); 
    let nd = new Date(utc + (3600000*offset)); 
    return nd.toLocaleString(); 
  }
  return (
    <MuiThemeProvider theme={muiTheme}>
    <div> 
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        
        <Card>
            <CardHeader plain color="warning">
              <Box display="flex">
                <Box flexGrow={1}>
                  <h4 className={classes.cardTitleWhite}>Survey Mapping</h4>
                </Box>
                <Box> 
                <Fab size="small" color="primary" aria-label="add">
                  <AddIcon onClick={()=>{
                    addSurvey()
                  }}/>
                </Fab> 
                </Box>
              </Box>
             
            </CardHeader>
            <CardBody> 
               <GridContainer
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
            {
              surveyTypesAlone.map((type)=>{
                {
                  return type.sur_typ_id!=undefined ? <GridItem xs={12} sm={6} md={3}>
                         <Card className={classes.card} >
                            <CardContent style={{"max-height":"230px","overflow":"auto"}}>
                            { type.survey_type_ver_id == undefined?
                                <span  style={{"float":"right"}} className={classes.icon} title="Delete Type"><Delete title="New Version" style={{ 'cursor': 'pointer', color: "red" }} onClick={()=>{
                                   setItemToDelete(type)
                                   setDisplayAlert(true)
                                }}/> </span>:""
                            }
                              
                              <span style={{"float":"right"}} className={classes.icon} title="Edit Type"><Edit onClick={()=>{
                                  editItem(type)
                              }}/> </span>
                              <span style={{"float":"right","color":"lightblue"}} className={classes.icon} title="View Type"><InfoIcon onClick={()=>{
                                  viewItem(type)
                              }}/> </span>
                              <Typography className={classes.title} color="textSecondary">
                                Survey Type
                              </Typography>
                              <Typography variant="headline" component="h2">
                                <span className={classes.overflow} title={type.survey_type}>{type.survey_type}</span>
                              </Typography>
                              
                              <Typography className={classes.pos} color="textSecondary">
                                Active Version
                              </Typography>
                              <Typography component="p">
                                {findActiveVersion(type.sur_typ_id) || <span style={{color:"red"}}>Versions are inactive</span>}
                              </Typography>
                                
                              {type.sur_typ_id != surveyTypeId? <a href="#" onClick={(e)=>{e.preventDefault();setSurveyTypeID(type.sur_typ_id)}}>More Details</a>:""}
                                { type.sur_typ_id == surveyTypeId?<div>
                                  <Typography className={classes.pos} color="textSecondary">
                                    Created by
                                  </Typography>
                                  
                                  <Typography component="p">
                                    {type.created_by}
                                  </Typography>

                                  <Typography className={classes.pos} color="textSecondary">
                                    Created at
                                  </Typography>
                                  <Typography component="p">
                                    {type.created_at}
                                  </Typography>
                                  <a href="#" onClick={(e)=>{e.preventDefault();setSurveyTypeID()}}>Hide Details</a>
                                  </div>:""
                                }  

                            </CardContent>
                            <CardActions> 
                              <Button size="small" onClick={()=>{createSuveryVersion(type.sur_typ_id)}} color="warning"><AddIcon style={{ 'cursor': 'pointer', color: "green" }} onClick={()=>{}}/> New Version</Button>
                              <Button size="small"  onClick={()=>{showVersions(type)}}>
                                <Badge badgeContent={getVersions(type.sur_typ_id)} color="secondary" classes={{ badge: classes.badge }}>
                                  <ListAltIcon style={{ 'cursor': 'pointer', color: "#72b7f2" }}/> Versions
                                </Badge>
                              </Button>
                            </CardActions>
                          </Card>
                     </GridItem> :""
                }
                 
              })
            }
            {
              excludeItems.map((type)=>{
                {
                  return type.sur_typ_id!=undefined ? <GridItem xs={12} sm={6} md={3}>
                         <Card className={classes.card} >
                            <CardContent style={{"max-height":"230px","overflow":"auto"}}>
                           <span  style={{"float":"right"}} className={classes.icon} title="Delete Type"><Delete title="New Version" style={{ 'cursor': 'pointer', color: "red" }} onClick={()=>{
                                   setItemToDelete(type)
                                   setDisplayAlert(true)
                                }}/> </span>
                              <span style={{"float":"right"}} className={classes.icon} title="Edit Type"><Edit onClick={()=>{
                                  editItem(type)
                              }}/> </span>
                              <span style={{"float":"right","color":"lightblue"}} className={classes.icon} title="View Type"><InfoIcon onClick={()=>{
                                  viewItem(type)
                              }}/> </span>
                              <Typography className={classes.title} color="textSecondary">
                                Survey Type
                              </Typography>
                              <Typography variant="headline" component="h2">
                                <span className={classes.overflow} title={type.survey_type}>{type.survey_type}</span>
                              </Typography>                               
                              <Typography className={classes.pos} color="textSecondary">
                                Active Version
                              </Typography>
                              <Typography component="p">
                                {findActiveVersion(type.sur_typ_id) || <span style={{color:"red"}}>Versions are inactive</span>}
                              </Typography>
                                
                              {type.sur_typ_id != surveyTypeId? <a href="#" onClick={(e)=>{e.preventDefault();setSurveyTypeID(type.sur_typ_id)}}>More Details</a>:""}
                                { type.sur_typ_id == surveyTypeId?<div>
                                  <Typography className={classes.pos} color="textSecondary">
                                    Created by
                                  </Typography>
                                  
                                  <Typography component="p">
                                    {type.created_by}
                                  </Typography>

                                  <Typography className={classes.pos} color="textSecondary">
                                    Created at
                                  </Typography>
                                  <Typography component="p">
                                    {type.created_at}
                                  </Typography>
                                  <a href="#" onClick={(e)=>{e.preventDefault();setSurveyTypeID()}}>Hide Details</a>
                                  </div>:""
                                }  

                            </CardContent>
                            <CardActions> 
                              <Button size="small" onClick={()=>{createSuveryVersion(type.sur_typ_id)}} color="warning"><AddIcon style={{ 'cursor': 'pointer', color: "green" }} onClick={()=>{}}/> New Version</Button>
                              <Button size="small"  onClick={()=>{showVersions(type)}}>
                                <Badge badgeContent={getVersions(type.sur_typ_id)} color="secondary" classes={{ badge: classes.badge }}>
                                  <ListAltIcon style={{ 'cursor': 'pointer', color: "#72b7f2" }}/> Versions
                                </Badge>
                              </Button>
                            </CardActions>
                          </Card>
                     </GridItem> :""
                }
                 
              })
            }
               
              </GridContainer>
              
             
            </CardBody>

          </Card>

          
        </GridItem>
      </GridContainer> 
       <Dialogs width={"600"} open={showDetailPane} title={"Suvery Version -"+suveryTitle} isEdit={false} content={
         <div> 
           <GridContainer
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
            { surveyVersionDetails && surveyVersionDetails.length ?
              surveyVersionDetails.map((type)=>{
                {
                  return type.ver_id!=undefined ? <GridItem xs={12} sm={6} md={6}>
                         <Card className={classes.card} >
                            <CardContent style={{"max-height":"240px","overflow":"auto"}}>
                           <span  style={{"float":"right"}} className={classes.icon} title="Delete Type">
                             
                           {type.isactive != 'Y'?<div onClick={()=>{
                                   setItemToDelete(type)
                                   setDisplayVersionAlert(true)
                                }}><Delete style={{ 'cursor': 'pointer', color: "red" }} /></div>:""}
                                
                                </span>
                              
                              <Typography className={classes.title} color="textSecondary">
                              Survey Version
                              </Typography>
                              <Typography variant="headline" component="p">
                                <span className={classes.overflow} title={type.survey_type_ver_id}>{type.survey_type_ver_id}</span>
                              </Typography>                               
                               
                                
                              <Typography className={classes.pos} color="textSecondary">
                                    Created by
                                  </Typography>
                                  
                                  <Typography component="p">
                                    {type.created_by_ver}
                                  </Typography>

                                  <Typography className={classes.pos} color="textSecondary">
                                    Created at
                                  </Typography>
                                  <Typography component="p">
                                    {type.created_at_ver}
                                  </Typography>
                            </CardContent>
                            <CardActions> 
                            <div> 
                              {type.isactive && type.count >0?<span>Status  <Switch
                                  onChange={(e)=>{changeVersionStatus(e,type)}}
                                  checked={type.isactive=='Y'?true:false}
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                /></span> :""}
                            </div>
                            <div>
                            {type.count >0 && <div  style={{ 'cursor': 'pointer',color:'red'}} onClick={(e)=>{downloadData(type)}} ><CloudDownloadIcon /> Download</div> }  
                          </div>
                          <div> 
                        {type.count == 0 ?
                            <Dropzone  onDrop={e => fileDrop(e, type)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept={acceptFileType} />
                                                 <p className={classes.margin0}>
                                                     <div  style={{ 'cursor': 'pointer',color:'green'}}> <CloudUploadIcon/>  Upload</div>

                                                    </p> 
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>:""}
                          </div> 
                            </CardActions>
                          </Card>
                     </GridItem> :""
                }
                 
              })
            :<div>No Versions available</div>}
            </GridContainer>
         </div>

      } handleClose={()=>{setDetailPane(false);}} handleSave={()=>{
           
        }}/>
      
      <Dialogs width={"500"} open={showAddDialog} title={!isEdit?"Add Survey Type":"Edit Survey Type"} isEdit={true} content={
         <div>
         <CustomInput
                labelText="Survey Type" 
                formControlProps={{
                  fullWidth: true
                }}

                inputProps={{   
                  value:surveyItem["survey_type"],
                  onChange: (e) => {
                    setSurveyItem({...surveyItem,survey_type:e.target.value})
                  }
                }}
              /> 
              <InputLabel id="mutiple-select-label">Encrypt params</InputLabel>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  style={{width:"100%"}}
                  value={selectedParams}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : ""
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedParams.length > 0 && selectedParams.length < encrptParams.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {encrptParams.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selectedParams.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
         </div>

      } handleClose={()=>{setShowAddDialog(false)}} handleSave={()=>{
          if(isEdit){
            updateSurveyType()
          }else{
            saveSurveyType();  
          }          
          setShowAddDialog(false)
        }}/>


        <Dialogs width={"500"} open={showInfoDialog} title={"Details"} isEdit={false} content={
         <div>  
              <dl className={classes.attributes}>
                <dt className={classes.key}>Survey Type</dt>
                <dd className={classes.value}>{surveyItem.survey_type}</dd>
               
                <dt className={classes.key}>Encrpt Params</dt>
                <dd className={classes.value}>{surveyItem.encrypt_params}</dd> 

                <dt className={classes.key}>Created by</dt>
                <dd className={classes.value}>{surveyItem.created_by}</dd>  
              </dl>       
         </div>

      } handleClose={()=>{setShowInfoDialog(false)}}/>



    </div>
    <ConfirmModal open={dispalyAlert} msg="Are you sure you want to remove this survey type?" confirm={deleteItem} cancel={() => { 
      setDisplayAlert(false) 
    }} /> 
    <ConfirmModal open={dispalyVersionAlert} msg="Are you sure you want to remove this survey version?" confirm={deleteVersionItem} cancel={() => { 
      setDisplayVersionAlert(false) 
    }} />         
    </MuiThemeProvider>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(SurveyMapping);
