import { createStore,applyMiddleware,compose } from "redux";
import thunk from 'redux-thunk';
import rootReducer from "../reducers/index";

/*import sagas from 'sagas'
import createSagaMiddleware from 'redux-saga'
const sagaMiddleware = createSagaMiddleware()*/
let composeEnhancers = compose
if (process.env.NODE_ENV === 'development') {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension
  }
}


const store = createStore(
	rootReducer,
	//applyMiddleware(thunk)
	composeEnhancers(applyMiddleware(thunk))
);
//sagaMiddleware.run(sagas)

export default store;
