const customForm = (state = {}, action) => {
  switch (action.type) {
    case 'CUSTOMER_STAGE':
      return {
          ...state,...{progress:1}
        }
      break;
    case 'ID_STAGE':
      return {
          ...state,...{progress:2}
        }
      break;
    case 'KYC_STAGE':
      return {
          ...state,...{progress:3}
        }
      break;
    default:
      return state
  }
}

export default customForm