
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { actions } from "actions"
import NewUser from "components/Dialog/NewUser.js"
import { findRoutes } from "utils"
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white"
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  }
}));

const mapStatetoProps = state => {
  if (state?.commonReducers?.group) {
    return {
      group: state.commonReducers.group
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setSession: (username, session) => {
      dispatch(actions.setSession(username, session))
    },
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    }
  }
}


function SignIn(props) {
  const classes = useStyles();
  const [username, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [newPw, setnewPw] = useState(false)
  const [userObj, setUserObj] = useState({})
  const [openSnackbar, setSnackbar] = useState(false)
  const [toast, setToast] = useState({
    message: "Username or password is wrong, Please try again.",
    color: "danger"
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(false);
  };

  const [loading, setLoading] = useState(false)

  const closeNewPw = () => {
    setnewPw(false);
  }
  const history = useHistory();
  const submit = (e) => {
    e.preventDefault()

    if (!username || !password) {
      props.showToaster("Username or password can not be empty, Please try again.", "error")
      return
    }
    setLoading(true);
    Auth.signIn(username, password)
      .then(async (user) => {
        setUserObj(user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setLoading(false);
          setPassword('')
          setnewPw(true);
        } else {
          let session = await Auth.currentSession()
          const { idToken } = session 
          props.setSession(user.username, idToken)
          if (idToken.payload["cognito:groups"]) {
            let groupRoutes = findRoutes(idToken.payload["cognito:groups"].toString())
            let routes = groupRoutes.filter((route)=>{return route.layout=="/amili"});
            localStorage.setItem("routes",JSON.stringify(routes))
            let path = routes[0].layout+routes[0].path
            history.push(path, { replace: true }); 
          }
          else {
            setLoading(false);
            props.showToaster("This account does not belong to any group", "error")
          }


        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        props.showToaster("Username or password is wrong, Please try again.", "error")
      });
  }
  const newUserSignUp = async (newPassword) => {
    setnewPw(false);
    Auth.completeNewPassword(
      userObj, // the Cognito User Object
      newPassword, // the new password
      { name: username } // TO DO - NAME, has to be set to user's
    )
      .then(async (user) => {
        let session = await Auth.currentSession()
        const { idToken } = session
        props.setSession(user.username, idToken)
        history.push('/amili/upload', { replace: true });
      })
      .catch((e) => {
        console.log(e);

      });
  }
  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} color="primary">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
        </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
              name="email"
              value={username}
              autoComplete="email"
              onChange={e => setUserName(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              name="password"
              label="Password"
              type="password"
              onChange={e => setPassword(e.target.value)}
              id="password"
              autoComplete="current-password"
            />
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
              >
                {!loading && "Sign In"}
                {loading && <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />}
              </Button>

            </div>
            <Grid container>
              <Grid item xs>
                <Link onClick={() => history.push('/page/forgotPassword', { replace: true })} variant="body2" >
                  Forgot password?
              </Link>
              </Grid>
            </Grid>
          </form>
          <NewUser open={newPw} handleClose={closeNewPw} newUserSignUp={newUserSignUp} />
        </div>

      </Container>
    </>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(SignIn)

