import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import {Auth} from "aws-amplify"; 
import { connect } from 'react-redux' 
import {actions}   from "actions"
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));
const mapStatetoProps = state => {  
    if(state?.commonReducers?.username){
      return{
        username:state.commonReducers.username,
        group:state.commonReducers.group
      }
    }
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
      setSession: (username,session) => { 
        dispatch(actions.setSession(username,session))
    }
  }

}
function AdminNavbarLinks(props) {
  const classes = useStyles();
  const classes1 = useStyles1()
  const history = useHistory(); 
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const signOut = async () => {
    try {
        await Auth.signOut();
        history.push("login");
        setOpenNotification(null);
        props.setSession(null,null)
    } catch (error) {
      console.log("error signing out: ", error);
    }    
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <div>
      
      <div className={classes.searchWrapper} style={{"position":"relative","top":"10px"}}>
        {/*<CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search"
            }
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>*/
        <>
        <InputLabel style={{color:"white"}}> Welcome {props.username}</InputLabel> 
         </>
      }

      </div>
        
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          style={{color:'black',margin:0}}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>{props.username}</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",color:'black'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                     
                   { /*<MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <Divider light />*/}                    
                      <div className={classes1.root}>                        
                        <Paper className={classes1.paper}>
                          <GridContainer wrap="nowrap" spacing={2}>
                            <GridItem>
                              <GroupIcon/>
                            </GridItem>
                            <GridItem xs>
                              <Typography>{props.group}</Typography>
                            </GridItem>
                          </GridContainer>
                        </Paper>
                        <Paper className={classes1.paper}>
                          <GridContainer wrap="nowrap" spacing={2}>                            
                            <GridItem xs>
                              <Fab size="small" color="primary">
                                <ExitToAppIcon onClick={signOut}/>
                              </Fab> 
                            </GridItem>
                          </GridContainer>
                        </Paper>
                      </div>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(AdminNavbarLinks)