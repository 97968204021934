import  * as userformActions from "./userformActions"
import * as customerAction from "./customerActions"
import * as customerformActions from "./customerformActions"
import * as formBuildActions from "./formBuildActions"
import * as commonActions from "./commonActions"
export const  actions = {
	...userformActions,
	...customerAction,
	...customerformActions,
	...formBuildActions,
	...commonActions
}