import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/Snackbar";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux'
import {actions}   from "actions"
const useStyles = makeStyles();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const mapDispatchToProps = dispatch => {
  return {
     hideToaster: () => { 
        dispatch(actions.hideToaster())
    }
  }
}
const mapStatetoProps = state => {   
  if (state?.commonReducers?.showToaster) {
    return {
      showToaster: state.commonReducers.showToaster,
      toasterMsg: state.commonReducers.toasterMsg,
      toasterType: state.commonReducers.toasterType
    }
  }
  return {}
}
function SnackbarComponent(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { message, openSnack, type,timeout } = props;  

  React.useEffect(()=>{
    if(props.showToaster){
      setTimeout(function() {
        props.hideToaster()  
        handleClose()      
      }, timeout?timeout:1000);       
    }

  },[props.showToaster])
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  } 
  React.useEffect(()=>{
    if(props.openSnack){
      setOpen(true)
    }
  },[props])
  return (<>
     {open ? <div> <Snackbar open={open}  autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose}  severity={type}>
           {message}
        </Alert>
      </Snackbar></div>:""}</>
  );
}

export default connect(mapStatetoProps, mapDispatchToProps)(SnackbarComponent)