import React, { useEffect, useState,useCallback  } from "react";
// @material-ui/core
// core components
import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
 import Button from '@material-ui/core/Button';
import CardIcon from "components/Card/CardIcon.js";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import Delete from '@material-ui/icons/Delete';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from "@material-ui/core/Icon";
import Edit from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import CustomInput from "components/CustomInput/CustomInput.js";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dropzone from "react-dropzone";

import { actions } from "actions"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Auth, API } from "aws-amplify"; 
import Amplify from 'aws-amplify';
import { Storage } from "aws-amplify";
import config from 'config';
const useStyles = makeStyles((theme) => ({
   root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  dropzoneStyle:{
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
    padding:"20px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  margin0:{
    margin:0,
    fontWeight:600
  }
})); 
const mapStatetoProps = state => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken,
      group:state.commonReducers.group
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    showToaster: (toasterMsg, toasterType) => {
      dispatch(actions.showToaster(toasterMsg, toasterType))
    }
  }
} 
const useStateCallback = (initialState)=> {
    const [state, setState] = useState(initialState);
    const cbRef = React.useRef(null);

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; 
      setState(state);
    }, []);

    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);

    return [state, setStateCallback];
}
function UploadPage_CSV(props) {
  const [fileNames, setFileNames] = useState("")
  const [dispalyAlert, setDisplayAlert] = useState(false)  
  const [filesuccess, setFileSuccess] = useState(false)  
  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rejectedFiles, setRejectedFiles] = React.useState([]);
  const [accepectedFiles, setAcceptedFiles] = React.useState([]);
  const [csvFormat, setCSVFormat] = useStateCallback([]);
  const [uploadItem, setUploadItem] = React.useState({});
  const [backdropProgress, setBackdropProgress] = React.useState(false)
  const history = useHistory();
  let acceptFileType = ".csv"
  const confirmTodelete = (item) => {
    //setItemToDelete(item);
    setDisplayAlert(true);
  }  
  const onFileChange = (event)=>{
      let files = []
      if(event.target && event.target.files){
        if(event.target.files.length){
            for(let i=0;i<event.target.files.length;i++){
               files.push(event.target.files[i])
            } 
        }
      }
      console.log(files)
  } 
  const classes = useStyles();

  const parseCsv = (data)=>{
      let parseData = []
      let newLineBreak = data.split("\n"); 
      for(let i=0;i<newLineBreak.length;i++){ 
          newLineBreak[i] = newLineBreak[i].replaceAll("\xEF\xBB\xBF","")
          if(newLineBreak[i].trim()!=""){
              parseData.push(newLineBreak[i].split(","))
          }
      }
      contructData(parseData)
  }

  

  const contructData = (parsedData)=>{
      let datas = []
      for(let i=1;i<parsedData.length;i++){ 
          let data = {};
          parsedData[i].forEach((item,index)=>{
              data[parsedData[0][index].trim()] = item.trim()
          })
          datas.push(data)
      }
      //console.log(datas)
      setCSVFormat(datas,(data)=>{
        console.log(data)
        uploadCSV(data)
      })
      
  }
  const uploadCSV = (data)=>{ 
    for(let i=0;i<data.length;i++){
        createKitIDCSV(data[i])
    }
    /*const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      }, 
      queryStringParameters: {
        upload: "csv"
      },      
      body: csvFormat
    };
    API.post("Amili-Service", "", myInit)
      .then((response) => {  
          setBackdropProgress(false) 
          setCSVFormat(response.items) 
      })
      .catch((err) => {
          setBackdropProgress(false)      
      });*/
  }
  const trackUploadStatus = (id,item)=>{
      let data = uploadItem
      data[id] = item
      setUploadItem({...uploadItem,...data})
  }
  const cancelUpload = ()=>{
    setCSVFormat([])
    setFileNames("")
  }
  const createKitIDCSV = (data) => { 
    data.isUploadProgress = true
    data.kitStatus = 4
    trackUploadStatus(data.kitID,data) 
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      }, 
      queryStringParameters: {
        upload: "csv"
      },      
      body: { ...data }
    };
    API.post("Amili-Service", "", myInit)
      .then((response) => {   
          data.status = response?.items?.uploadStatus
          data.isUploadProgress = false;
         trackUploadStatus(data.kitID,data) 
      })
      .catch((err) => {
         data.isUploadProgress = false
         data.status = "Failed to create kitID"
         trackUploadStatus(data.kitID,data);       
      });

  }
  const fileDrop = async (e, col)=> {  
        setShowProgress(true)
        setCSVFormat([])
        setUploadItem({})
        var self = this
        const convertToFile = (file) => { 
             let fileReader = new FileReader();
             fileReader.addEventListener("load",(e)=>{
                 parseCsv(e.target.result)
             })
             fileReader.readAsBinaryString(file)
        }
        const files = e; 
        let arr  = [] 
        for(let i=0;i<files.length;i++){
          if(files[i].name.indexOf(acceptFileType)!=-1){
              let len = files[i].name;
              if(files[i].name.substr(files[i].name.lastIndexOf(".")) == acceptFileType){
                arr.push(files[i].name) 
                setAcceptedFiles(oldArray => [...oldArray, files[i]]); 
              }              
          }else{ 
               setRejectedFiles(oldArray => [...oldArray, files[i]]);  
          }          
        } 
        
        setFileNames(arr)
        if(arr.length){ 
             for(let i=0;i<files.length;i++){ 
                convertToFile(files[i]);
                setShowProgress(false) 
             }
        }else{
          setShowProgress(false)
          setFileNames("")
          props.showToaster("File(s) type may be not supported", "error")
        } 
    }   
     
     const downloadSample = ()=>{
        var samples = ["kitBatch,kitID,sequenceID,analysisID", "batch_1,AML_BME000098,AML_BME000097201210,BM000218", "batch_2,AML_BME000097,AML_BME000097201210,BM000218"]                  
        var CSV = ''; 
        for (var i = 0; i < samples.length; i++) {           
            CSV += samples[i]+ '\r\n';           
        } 
        var fileName = "amili-kits";  
        var uri = 'data:text/csv;charset=utf-8,' + CSV; 
        var link = document.createElement("a");    
        link.href = uri; 
        link.style = "visibility:hidden";
        link.download = fileName + ".csv"; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
     }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader plain color="warning">
              <Box display="flex"> 
               <Box flexGrow={1}>
                  <h4 className={classes.cardTitleWhite}>Upload CSV Files</h4>                
                </Box>     
                <Box>
                  <Button color="warning" onClick={downloadSample}>Sample Download</Button>                   
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
            {showProgress? <LinearProgressWithLabel value={progress} />:""}
            <div class={classes.dropzoneStyle}>
              
              <Dropzone  onDrop={e => fileDrop(e, "property_filename")}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept={acceptFileType}/>
                                                {fileNames ? (<div className={classes.margin0}>{fileNames.toString()}</div>):<p className={classes.margin0}>Drag 'n' drop some files here, or click to select files</p>}
                                               {!fileNames.length ? <em>Only supported {acceptFileType} </em>:""} 
                                            </div>
                                        </section>
                                    )}
                                </Dropzone></div>
                                {csvFormat.length!=0 && <div><MaterialTable
                title="" 
                columns={[
                  { title: 'Kit Batch',defaultSort:"asc",cellStyle: {
                      width: '10%'
                    }, field: 'kitBatch'},
                  { title: 'Kit ID',cellStyle: {
                      width: '15%'
                    }, field: 'kitID'}, 
                    {
                    title: 'Sequence ID', field: 'sequenceID'
                  },
                  {
                    title: 'Analysis ID', field: 'analysisID'
                  }, 
                  {
                    title: 'Status', field: 'uploadStatus', 'align': 'left',render: rowData => {
                      return (
                        <div> 
                          {
                            uploadItem[rowData.kitID]?.isUploadProgress && rowData.SORTID == uploadItem[rowData.kitID]?.SORTID?
                            <CircularProgress color="secondary" size={24} className={classes.buttonProgress} />:"" 
                          }
                          {
                            uploadItem[rowData.kitID]?.status?uploadItem[rowData.kitID]?.status:""
                          }
                        </div>)
                    }
                  }
                ]}
                data={csvFormat} 
                options={{
                  sorting: true,
                  pageSize:csvFormat.length?(csvFormat.length<50?csvFormat.length:50):10,
                  pageSizeOptions:[5,10,20,50,100]
                }}
              />
              <br/>
              
              {/*<Button variant="contained" color="primary" style={{float:"right"}} onClick={uploadCSV}>
                  Upload
              </Button>*/}
              <Button variant="contained" color="secondary" style={{float:"right","margin-right":"10px"}} onClick={cancelUpload}>
                  Cancel
              </Button>
              </div>
            }
              
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>

      <Backdrop className={classes.backdrop} open={backdropProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <LinearProgress  {...props} />
      </Box> 
    </Box>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(UploadPage_CSV)