import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import store from 'store'
//import { createBrowserHistory } from "history";
import { ThemeProvider } from '@material-ui/core';
import {  
  Route, 
  Switch, 
  Redirect,
  HashRouter 
} from "react-router-dom";
import theme from 'theme';
import GlobalStyles from 'components/Pages/GlobalStyles';
// core components
import Admin from "layouts/Admin.js";  
import Page from "layouts/Page.js";  
import UserForms from "views/Forms/UserForms.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import Amplify from 'aws-amplify';
import Storage from '@aws-amplify/storage';
import config from 'config';
//const hist = createBrowserHistory();
//console.log(process.env,config)
Amplify.configure({
   ...config
});
ReactDOM.render(
  <Provider store={store}> 
  <HashRouter>
    <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Switch>
      <Redirect exact from="/" to="/page/login" />
      <Route path="/amili" component={Admin} />  
      <Route exact path="/page/login" component={Page}/>
       <Route exact path="/page/forgotpassword" component={Page}/>       
    </Switch>
    </ThemeProvider>
  </HashRouter>
  </Provider>,
  document.getElementById("root")
);
