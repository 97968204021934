import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import {
  Box 
} from '@material-ui/core';
import CardFooter from "components/Card/CardFooter.js";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js'
import { get } from 'react-hook-form';
import { Auth, API } from "aws-amplify";
import AWS from "aws-sdk";
/*AWS.config.update({
  accessKeyId: "AKIA4DEAKY2E47KPKCHB",
  secretAccessKey: "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",
});
*/

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);


export default function VirtualSettings(props) {

  const classes = useStyles();
  // const [customerData, setCustomerData] = useState({})
  const [name, setName] = useState("");
  const [frameColor, setFrameColor] = useState("");
  const [primaryBtnColor, setPrimaryColor] = useState("");
  const [secondaryBtnColor, setSecColour] = useState("");
  const [logoUrl, setLogoURL] = useState("");
  const [favIconUrl, setFaviIconURL] = useState("");
  const [toast, setToast] = useState({
    message: "Your settings has been saved",
    color: "success"
  });

  const [MerchantGroup, setGroup] = useState("");
  const [SID, SetSID] = useState("");

  const [open, setOpen] = React.useState(false);


  const showToast = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  const getUserSettings = async () => {
    const { idToken } = await Auth.currentSession();
    const myInit = {
      headers: {
        "X-COG-KEY": idToken.jwtToken,
      },
      queryStringParameters: {
        detailView: true,
      },
    };
    API.get("Nimo-Service", "merchantprofile", myInit).then((response) => {
      console.log(response);

      const userSetting = response.items[0]
      setName(userSetting.companyName)
      setGroup(userSetting.merchantGroup);
      SetSID(userSetting.SID);
      setFrameColor(userSetting.values[0].frameColor)
      setPrimaryColor(userSetting.values[0].primaryBtnColor)
      setSecColour(userSetting.values[0].secondaryBtnColor)
      setLogoURL(userSetting.values[0].logoUrl || "")
      setFaviIconURL(userSetting.values[0].favIconUrl || "")
    });
  }
  const convertPhoto = (img) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onloadend = function () {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(img);
    });
  }

  const uploadFile = async (files, type) => { 
    const s3 = new AWS.S3();
    let base64 = await convertPhoto(files[0]);
    const base64Data = new Buffer(
      base64.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const imgtype = base64.split(";")[0].split("/")[1];
    if(type == "favIcon"){
      setFaviIconURL(files[0].name)
    }else{
      setLogoURL(files[0].name)
    }
    const params = {
      Bucket: `nimo-merchant/${type}`,
      Key: `${name}.${imgtype}`,
      Body: base64Data,
      ACL: "public-read-write",
      ContentEncoding: "base64", // required
      ContentType: `image/${imgtype}`,
    };
    const { idToken } = await Auth.currentSession();
    function callback(err, data) {
      if (err) {
        return console.log(err);
      }
      console.log(data.Location);
      if (type === 'logo') setLogoURL(data.Location);
      else if (type === 'favIcon') setFaviIconURL(data.Location)

    }
    s3.upload(params, callback);
  }


  const saveUserDetails = async () => {
    // console.log({ name, frameColour, secondaryBtnColor, primaryBtnColor })
    const { idToken } = await Auth.currentSession();
    const myInit = {
      headers: {
        "X-COG-KEY": idToken.jwtToken,
      },
      body: {
        companyName: name,
        merchantGroup: MerchantGroup,
        SID: SID,
        values: [{
          primaryBtnColor,
          secondaryBtnColor,
          frameColor,
          logoUrl,
          favIconUrl
        }],
      },
    };
    console.log(myInit);

    API.put("Nimo-Service", "merchantprofile", myInit)
      .then(() => {
        setToast({
          color: "success",
          message: "Your settings was saved!"
        }
        )
        showToast();
      })
      .catch((e) => {
        setToast({
          color: "danger",
          message: "Something went wrong! Try again."
        }
        )
        showToast();
      });
  }
  useEffect(() => {
    getUserSettings();
  }, []);



  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText="Company Name"
                    id="cname"

                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: name,
                      onChange: (e) => setName(e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Frame Colour"
                    id="frame-colour"
                    inputProps={{
                      value: frameColor,
                      onChange: (e) => setFrameColor(e.target.value)
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Primary Colour"
                    id="primary-colour"
                    inputProps={{
                      value: primaryBtnColor,
                      onChange: (e) => setPrimaryColor(e.target.value)
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Secondary color"
                    id="Secondary-color"
                    inputProps={{
                      value: secondaryBtnColor,
                      onChange: (e) => setSecColour(e.target.value)
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}

                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                 <Box display="flex"> 
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                      labelText="Logo" 
                      inputProps={{
                        value: logoUrl
                      }}
                      formControlProps={{ 
                      }}
                    />
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: 'none' }}
                      id="upload-photo"
                      name="upload-photo"
                      onChange={(e) => { uploadFile(e.target.files, 'logo') }}
                      type="file"
                      accept={".png,.jpeg,.jpg,.gif"}
                    />
                    <Button color="primary" variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                </GridItem>
                </Box>
                 <Box display="flex"> 
                <GridItem xs={12} sm={12} md={12}> 
                    <CustomInput
                      labelText="FavIcon" 
                      inputProps={{
                        value: favIconUrl
                      }}
                      formControlProps={{ 
                      }}
                    />
                    <label htmlFor="upload-icon">
                      <input
                        style={{ display: 'none' }}
                        id="upload-icon"
                        name="upload-icon"
                        type="file"
                        accept={".png,.jpeg,.jpg,.gif"}
                        onChange={(e) => { uploadFile(e.target.files, 'favIcon') }}
                      />
                      <Button color="primary" variant="contained" component="span">
                        Upload
                      </Button>
                    </label>
                 
                </GridItem> </Box>
              </GridContainer>


            </CardBody>
            <CardFooter>
              <Button color="primary" startIcon={<SaveIcon />} onClick={saveUserDetails}>Save</Button>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>


      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}

      >
        <SnackbarContent
          color={toast.color}
          message={<span id="client-snackbar">{toast.message}</span>}
        />
      </Snackbar>
    </div>
  );
}