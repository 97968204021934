import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialogs from "components/Dialog/Dialogs.js";
import CardFooter from "components/Card/CardFooter.js";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { API } from "aws-amplify";
import { connect } from 'react-redux'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import { ReactSortable,Sortable, MultiDrag, Swap } from "react-sortablejs";
import FormBuilder from "views/Forms/FormBuilder.js"
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import EditModule from "views/Modules/EditModule.js";
import {actions}   from "actions"
import {
  Box,
  Divider,
  IconButton,
  List,
  Button,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
const data = [
  {

    name: 'User Verify'
  },
  {

    name: 'User Verify1'
  }
];
const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  rountedBtn: {
    borderRadius: "55px",
    margin: "2px"
  }
});

const useStyles = makeStyles(styles);
const mapStatetoProps = state => {
  if (state?.commonReducers?.idToken) {
    return {
      idToken: state.commonReducers.idToken
    }
  }
  return {}
}

const mapDispatchToProps = dispatch => {
 return {
      showToaster: (toasterMsg,toasterType) => { 
        dispatch(actions.showToaster(toasterMsg,toasterType))
      }
  }
}


const updateForms = (name, acModules, forms, isPublished, props, history) => {
  //if(this.$refs.newform.validate()){
  if (!acModules || !acModules.length) {
    return
  }
  let steppers = []
  let formData = {
    "SID": forms.SID,
    "ID": forms.ID,
    "name": name,
    "status": !isPublished ? "Available" : "Published",
    "values": {
      "stepper": true,
      "steppers": steppers
    }
  }
  acModules.forEach((mactive, index) => {
    let step = {}
    if (mactive.values) {
      step.stepId = mactive.name
      step.title = mactive.name
      step.progress = index + 1
      step.data = { "forms": mactive.values }
      steppers.push(step)
      mactive.values.forEach((ma) => {
         ma.visible = ma.visible
         ma.required = ma.required
      })
    }
  })
  const myInit = {
    headers: {
      "X-COG-KEY": props.idToken
    },
    body: { ...formData },
  };
  API.put("Nimo-Service", "customerform", myInit).then((response) => {
    props.showToaster("Form has been updated successfully","success")
    history.push("/nimo/dashboard")
  }).catch((err) => {
    props.showToaster("Failed in updated the form","error")
  });
  //}
}

function EditCustomerForms(props) {
  const classes = useStyles();
  const [avModules, setAvModules] = useState([]);
  const [acModules, setAcModules] = useState([]);
  const { register, handleSubmit, errors } = useForm();
  const [openDialog, setOpenDialog] = useState(false);
  const [modules, setModules] = useState(false);
  const [disabledName, setDisabledName] = useState(true);
  const [name, setName] = useState("");
  const [forms, setForms] = useState({});
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setDelete] = useState(false)
  const [isEditForm,setEditForm] = useState(true)
  const [isView, setIsView] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  const handleSave = () => {
    setOpenDialog(false)
    console.log(modules)
    acModules.forEach((aM) => {
      if (aM.name == modules.name) {
        aM.values = modules.values
      }
    })
    setAcModules(acModules)
  }
  const viewModules = module => {
    setOpenDialog(true)
    setIsView(true)
    setIsEdit(false)
    setModules(module)
  }
  const addModule = (module, index) => {
    let modules = [...acModules, ...[avModules.splice(index, 1)[0]]]
    setAcModules(modules)

  }
  const removeModule = (module, index) => {
    let modules = [...avModules, ...[acModules.splice(index, 1)[0]]]
    setAvModules(modules)
    setDelete(true)
  }
  const editModule = (module, index) => {
    setOpenDialog(true)
    setIsEdit(false)
    setIsView(false)
    //setModules(JSON.parse(JSON.stringify(module)))
    setModules(module)
  }
  const saveForms = (isPublished) => {
    if(!name){
      props.showToaster("Please provide form name", "error")
      return;
    }
    updateForms(name, acModules, forms, isPublished, props, history)
  }
  const constructEditForms = () => {
    let editacModules = []
    let editModules = []
    if (forms && forms.values && !isDelete && isEditForm) {
      let items = forms.values.steppers;
      items.forEach((it) => {
        if (avModules) {
          avModules.forEach((md, index) => {
            if (md.name == it.stepId) {
              for (var i = 0; i < md.values.length; i++) {
                md.values[i].visible = it.data.forms[i].visible
                md.values[i].required = it.data.forms[i].required
              }
              editacModules.push(md)
              editModules = avModules.splice(index, 1)
            }
          })
        }
      }) 
       setAcModules(editacModules); 
    }
  }
  const getModules = () => {
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
    };
    API.get("Nimo-Service", "module", myInit).then((response) => {
      setAvModules(response.items);
    });
  }
  const getCustomerForms = () => {
    const myInit = {
      headers: {
        "X-COG-KEY": props.idToken,
      },
      queryStringParameters: {
        "SID": props.match.params.customerformId + "#" + props.match.params.group
      }
    };
    API.get("Nimo-Service", "customerform", myInit).then((response) => {
      if (response.items && response.items.length) {
        setForms(response.items[0]);
        setName(response.items[0].name);
      }

    });
  }
  useEffect(() => {
    if (props.idToken) {
      getModules()
      getCustomerForms()
    }
  }, [props.idToken])
  useEffect(() => {
    const editForms = () => {
      if (forms && avModules && avModules.length && isEditForm) {
        setTimeout(function() {
          constructEditForms()
          setEditForm(false)  
        },500);
        
      }
    }
    editForms()
  }, [forms, avModules])
  return (
    <>
      <GridContainer>

        <GridItem xs={12} sm={12} md={6}>
          <h4>Edit Forms</h4>
          <Card>
            <CardBody>
              <CustomInput
                labelText="Form Name"
                id="cname"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: disabledName,
                  value: name,
                  onChange: (e) => {
                    setName(e.target.value)
                  }
                }}
              />
              {disabledName ?
                <Link onClick={() => { setDisabledName(false) }}>
                  Edit Name
                </Link> : ""}
              {!disabledName ?
                <Link onClick={(e) => { setDisabledName(true) }}>
                  Save
                </Link> : ""}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>
                Available Modules
              </h4>
            </CardHeader>
            <CardBody style={{ 'height': '250px' }}>
             
                <List>
                    <ReactSortable 
                      list={avModules}
                      animation={200}
                      group="modules"
                      height="250px"
                      setList={setAvModules} 
                      className={classes.ht}
                    >
                     {avModules.length ?<>
                  {avModules.map((module, i) => (

                    <ListItem
                      divider={i < avModules.length - 1}
                      key={module.name}
                    ><IconButton
                      edge="end"
                      size="small"

                    >
                        <MoreVertIcon />
                      </IconButton>

                      <ListItemText
                        key={`ac-name-${i}`}
                        primary={module.name}
                      />
                      <ListItemSecondaryAction>
                        <Button color="primary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { viewModules(module, i) }}>View</Button>
                        <Button color="primary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { addModule(module, i) }}>Add</Button>
                      </ListItemSecondaryAction>

                    </ListItem>

                  ))}</>
                  : null}
                   </ReactSortable>
                </List> 
            </CardBody>
            <CardFooter>

            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <Card style={{ 'height': '310px' }}>
            <CardHeader plain color="warning">
              <h4 className={classes.cardTitleWhite}>
                Active Modules   
            </h4>
            </CardHeader>
            <CardBody>
              
                <List>
                 <ReactSortable
                  list={acModules}
                  group="modules" 
                  animation={200}
                  height="350px"
                  setList={setAcModules}
                  className={classes.ht}
                >
                {acModules.length ?<>
                  {acModules.map((module, i) => (
                    <ListItem
                      divider={i < acModules.length - 1}
                      key={module.name}
                    ><IconButton
                      edge="end"
                      size="small"

                    >
                        <MoreVertIcon />
                      </IconButton>

                      <ListItemText
                        key={`ac-name-${i}`}
                        primary={module.name}
                      />
                      <ListItemSecondaryAction>
                        <Button color="primary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { editModule(module, i) }}>Edit</Button>
                        <Button color="secondary" size="small" variant="outlined" className={classes.rountedBtn} onClick={(e) => { removeModule(module, i) }}>Delete</Button>
                      </ListItemSecondaryAction>

                    </ListItem>
                  ))}</>:null
                }
                  </ReactSortable>
                </List>  
            </CardBody>
             
          </Card>
        </GridItem>

      </GridContainer>
      <GridContainer>
        <Box m={2} pt={3}>
          <Button color="primary" variant="contained" onClick={() => { saveForms(false) }} startIcon={<SaveIcon />}>Save</Button>
        </Box>
        <Box m={2} pt={3}>
          <Button color="primary" variant="contained" onClick={() => { saveForms(true) }} startIcon={<CloudUploadIcon />}>Save & Publish</Button>
        </Box>
      </GridContainer>
     {!isView ? <Dialogs title={modules.name} isEdit={isEdit} content={<EditModule data={modules} />} handleSave={handleSave} open={openDialog} handleClose={handleDialogClose} /> : ""}
      {isView ? <Dialogs title={modules.name} isEdit={false} content={<FormBuilder
        register={register}
        readMode={true}
        verifyMail={() => { }}
        resend={() => { }}
        errors={errors}
        data={modules}
      />} handleSave={handleSave} open={openDialog} handleClose={handleDialogClose} /> : null}

    </>
  );
}
export default connect(mapStatetoProps, mapDispatchToProps)(EditCustomerForms)